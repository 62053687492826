/**
 * Handles changes in the form.
 */
import { useState } from "react";
export function useForm(initialValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnChange)
      validate(
        { [name]: value },
        errorMessages,
        setErrorMessages,
        errors,
        setErrors
      );
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrorMessages({});
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    errorMessages,
    setErrorMessages,
    handleInputChange,
    resetForm,
  };
}
