/**
 * Buttons for catalog forms.
 */
import React from "react";
import Controls from "./Controls";
import Grid from "@mui/material/Grid";

export default function CatalogButtons(props) {
  const { handleSubmit, values, setOpenPopup } = props;
  return (
    <Grid container justifyContent="center">
      <Controls.ActionButton
        color="primary"
        variant="contained"
        onClick={() => {
          handleSubmit(values);
          setOpenPopup(false);
        }}
      >
        Add
      </Controls.ActionButton>
    </Grid>
  );
}
