/**
 *  Exports of different colored text from material ui.
 */
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const GreenTextTypography = styled(Typography)(({ theme }) => ({
  color: "#82CA9D",
}));

export const GoldTextTypography = styled(Typography)(({ theme }) => ({
  color: "#F9A620",
}));

export const BlackTextTypography = styled(Typography)(({ theme }) => ({
  color: "#000000",
}));

export const WhiteTextTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
}));

export const ColorTypography = styled(Typography)(({ theme, color }) => ({
  color: color,
}));
