/**
 *  Buttons for exam forms.
 */
import Grid from "@mui/material/Grid";
import Controls from "./Controls";
import React, { useState } from "react";

export default function ExamSubmitButtons(props) {
  const { values, validate, handleSubmit, setOpenPopup } = props;
  const [disableButton, setDisableButton] = useState(false);
  const disableSubmit = () => {
    if (validate(values)) {
      setDisableButton(true);
      handleSubmit(values);
      setOpenPopup(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {/* {manual ? (
        <Typography component="div">
          <Typography>Test Type</Typography>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Automatic</Grid>
            <Grid item>
              <AntSwitch
                checked={manual}
                onChange={toggleManual}
                name="manualTest"
              />
            </Grid>
            <Grid item>Manual</Grid>
          </Grid>
        </Typography>
      ) : null} */}
      <Controls.Button
        // text={manual ? "Manual Test" : "Automatic Test"}
        text={disableButton ? "Starting Test" : "Automatic Test"}
        color="primary"
        variant="contained"
        disabled={disableButton}
        onClick={disableSubmit}
      />
    </Grid>
  );
}
