/**
 * Handles states of authentication.
 */
import {
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_REFRESH,
  AUTHENTICATED_REFRESH_SUCCESS,
  AUTHENTICATED_REFRESH_FAIL,
  AUTHENTICATED_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  LOGOUT,
} from "../actions/types";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  user: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AUTHENTICATED_REFRESH:
      localStorage.removeItem("access");
      return {
        ...state,
        access: null,
      };
    case AUTHENTICATED_REFRESH_SUCCESS:
      localStorage.setItem("access", payload.access_token);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
      };
    case AUTHENTICATED_REFRESH_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
      };
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false,
      };
    case GOOGLE_AUTH_SUCCESS:
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh", payload.refresh_token);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access_token,
        refresh: payload.refresh_token,
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
      };
    case GOOGLE_AUTH_FAIL:
    case LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}
