/**
 * Condenses all implemented material ui components into one file.
 * For uses like Controls.TextFields.
 */
import Input from "./Input";
import Button from "./Button";
import ActionButton from "./ActionButton";
import { AntSwitch } from "./Switch";
import ToggleButtons from "./ToggleButtons";
import ChartButtons from "./ChartButtons";
import CalibrationSubmitButtons from "./CalibrationSubmitButtons";
import Slider from "./Slider";
import ExamSubmitButtons from "./ExamSubmitButtons";
import ExamControls from "./ExamControls";
import Select from "./Select";
import TextFields from "./TextFields";
import CalibrationControls from "./CalibrationControls";
import TableCalResultsButtons from "./TableCalResultsButtons";
import TableAmplifierButtons from "./TableAmplifierButtons";
import TableCatalogButtons from "./TableCatalogButtons";
import ExamCells from "./ExamCells";
import TableExamResultsButtons from "./TableExamResultsButtons";
import Knob from "./Knob";
import Fab from "./Fab";
import RangeSlider from "./RangeSlider";
import CatalogButtons from "./CatalogButtons";
import CatalogEditButtons from "./CatalogEditButtons";
import DialogTitle from "./DialogTitle";
import { Dialog } from "./Dialog";
import Popup from "./Popup";
import InstrumentSubmitButtons from "./InstrumentSubmitButtons";
import ScreenShot from "./ScreenShot";
import InstrumentTableButtons from "./InstrumentTableButtons";
import StandCard from "./StandCard";
import ExamCard from "./ExamCard";
/* Allows for easy reuse of Material UI components */
const Controls = {
  Input,
  Button,
  ActionButton,
  AntSwitch,
  ToggleButtons,
  ExamCells,
  ChartButtons,
  CalibrationSubmitButtons,
  CalibrationControls,
  Slider,
  ExamSubmitButtons,
  ExamControls,
  Select,
  TextFields,
  TableCalResultsButtons,
  TableAmplifierButtons,
  TableCatalogButtons,
  TableExamResultsButtons,
  Knob,
  Fab,
  RangeSlider,
  CatalogButtons,
  CatalogEditButtons,
  DialogTitle,
  Dialog,
  Popup,
  InstrumentSubmitButtons,
  ScreenShot,
  InstrumentTableButtons,
  StandCard,
  ExamCard,
};

export default Controls;
