/**
 *  Styling for sliders.
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiSlider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import React from "react";
import { styled } from "@mui/material/styles";

export const GridContainer = styled(Grid)(({ theme }) => ({
  width: 250,
}));

export const Input = styled(MuiInput)(({ theme }) => ({
  width: 50,
}));

/* Allows for easy reuse of Material UI TextField component */

export default function Slider(props) {
  const {
    title,
    name,
    handleSliderChange,
    value,
    min,
    max,
    step,
    handleOffsetChange,
    handleBlur,
  } = props;

  const handleNamedSliderChange = (event, newValue) => {
    handleSliderChange(event, newValue, name);
  };

  const handleNamedOffsetChange = (event) => {
    handleOffsetChange(event, name);
  };

  const handleNamedBlur = () => {
    handleBlur(name);
  };

  return (
    <GridContainer key="offset" container justifyContent="center">
      <Typography gutterBottom>{title}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <MuiSlider
            value={typeof value === "number" ? value : 0}
            marks
            step={typeof step === "number" ? step : 10}
            onChange={handleNamedSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby={name}
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            margin="dense"
            onChange={handleNamedOffsetChange}
            onBlur={handleNamedBlur}
            name={name}
            inputProps={{
              step: typeof step === "number" ? step : 10,
              min: typeof min === "number" ? min : 0,
              max: typeof max === "number" ? max : 100,
              type: "number",
              "aria-labelledby": { name },
            }}
          />
        </Grid>
      </Grid>
    </GridContainer>
  );
}
