/**
 * Buttons for charts.
 */
import Controls from "./Controls";
import React from "react";
import Grid from "@mui/material/Grid";

export default function ChartButtons(props) {
  const { inProcess, clear } = props;
  return (
    <Grid container justifyContent="center">
      {!inProcess ? (
        <>
          <Controls.Button
            text="Clear Data"
            color="primary"
            variant="contained"
            onClick={() => {
              clear(false);
            }}
          />
        </>
      ) : null}
    </Grid>
  );
}
