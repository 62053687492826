/**
 * Handles authorization for webpage.
 */
import axios from "axios";

import {
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_REFRESH,
  AUTHENTICATED_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  LOGOUT,
  AUTHENTICATED_REFRESH_SUCCESS,
  AUTHENTICATED_REFRESH_FAIL,
} from "./types";
import { googleLogout } from "@react-oauth/google";
axios.defaults.withCredentials = true;

axios.defaults.withCredentials = true;

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({
      access_token: localStorage.getItem("access"),
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/me/`,
        body,
        config
      );
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data.user,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          type: AUTHENTICATED_REFRESH,
        });
        dispatch(refreshToken());
      }
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const refreshToken = () => async (dispatch) => {
  if (localStorage.getItem("refresh")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ refresh: localStorage.getItem("refresh") });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`,
        body,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: AUTHENTICATED_REFRESH_SUCCESS,
          payload: res.data,
        });
        dispatch(load_user());
      } else {
        dispatch({
          type: AUTHENTICATED_REFRESH_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_REFRESH_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_REFRESH_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const body = JSON.stringify({
      access_token: localStorage.getItem("access"),
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/token/verify/`,
        body,
        config
      );
      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          type: AUTHENTICATED_REFRESH,
        });
      }

      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    if (localStorage.getItem("refresh")) {
      dispatch({
        type: AUTHENTICATED_REFRESH,
      });
      dispatch(refreshToken());
    }
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const login = (data) => (dispatch) => {
  if (data) {
    dispatch({
      type: GOOGLE_AUTH_SUCCESS,
      payload: data,
    });
    dispatch(load_user());
  } else {
    dispatch({
      type: GOOGLE_AUTH_FAIL,
    });
  }
};

export const logout = () => (dispatch) => {
  googleLogout();
  dispatch({
    type: LOGOUT,
  });
};
