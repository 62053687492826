/**
 * Home page.
 */
import React, { useEffect, useState } from "react";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Controls from "../controls/Controls";
import { Grid, Typography } from "@mui/material";
import { CalibrationAPIRouter } from "../Routers/CalibrationAPIRouter";
import ExamAPIRouter from "../Routers/ExamAPIRouter";

export default function Home(props) {
  const [activeStands, setActiveStands] = useState([]);
  const [recentExams, setRecentExams] = useState([]);

  const getActiveStands = async () => {
    let catalogAPIRouter = new CatalogAPIRouter();
    await catalogAPIRouter
      .getActiveStands()
      .then((data) => setActiveStands(data.stands ? data.stands : []));
  };

  const getRecentExams = async () => {
    let calibrationAPIRouter = new CalibrationAPIRouter();
    let examAPIRouter = new ExamAPIRouter();
    const cals = await calibrationAPIRouter.getRecentCalibrations();
    const satExams = await examAPIRouter.getRecentSatExams();
    const burnInExams = await examAPIRouter.getRecentBurnInExams();
    setRecentExams([...cals, ...satExams, ...burnInExams]);
  };
  useEffect(() => {
    getActiveStands();
    getRecentExams();
    const intervalCall = setInterval(() => {
      getActiveStands();
      getRecentExams();
    }, 30000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  const stands = () => (
    <>
      <Grid container justifyContent="center" alignContent="center">
        <Grid item xs>
          <Typography variant="h4">
            {activeStands.length ? null : "No "}Active Stands
          </Typography>
          <Grid container justifyContent="center" alignContent="center">
            {activeStands.map((stand, index) => {
              return (
                <Controls.StandCard key={`standCard${index}`} stand={stand} />
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">
            {recentExams.length ? null : "No "}Recent Exams
          </Typography>
          <Grid container justifyContent="center" alignContent="center">
            {recentExams.map((exam, index) => {
              return <Controls.ExamCard key={`examCard${index}`} item={exam} />;
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  return <>{stands()}</>;
}
