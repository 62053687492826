/**
 * Data within the navbar component
 */
import React from "react";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BarChartIcon from "@mui/icons-material/BarChart";
import SpeedIcon from "@mui/icons-material/Speed";
import MemoryIcon from "@mui/icons-material/Memory";
import ConstructionIcon from "@mui/icons-material/Construction";
import { GiAmplitude } from "react-icons/gi/";
import { WiTrain } from "react-icons/wi/";

export const NavbarData = [
  {
    text: "Home",
    icon: <HomeIcon />,
    link: "/",
  },
  {
    text: "Instruments",
    icon: <ConstructionIcon />,
    link: "/instruments",
  },
  {
    text: "Catalog",
    icon: <MenuBookIcon />,
    /* link: "/catalog", */
    nested: [
      {
        text: "Cables",
        icon: <SettingsInputComponentIcon />,
        link: "/catalog/cables",
      },
      {
        text: "Couplers",
        icon: <MemoryIcon />,
        link: "/catalog/couplers",
      },
      {
        text: "Amplifiers",
        icon: <GiAmplitude size={25} />,
        link: "/catalog/amplifiers",
      },
    ],
  },
  {
    text: "Calibrations",
    icon: <SpeedIcon />,
    link: "/calibration",
  },
  {
    text: "Test",
    icon: <BarChartIcon />,
    link: "/test",
  },
  // {
  //   text: "Full Exam",
  //   icon: <WiTrain size={25} />,
  //   link: "/full",
  // },
];

export default NavbarData;
