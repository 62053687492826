/**
 *  Buttons for the catalog table handing mainly CRUD functionality.
 */
import React, { useState } from "react";
import Controls from "./Controls";
import { useNavigate } from "react-router-dom/";
import SpeedIcon from "@mui/icons-material/Speed";
import CloseIcon from "@mui/icons-material/Close";
import EditOutLinedIcon from "@mui/icons-material/EditOutlined";
import Form from "../Form/Form";
import DeleteItemForm from "../Form/DeleteItemForm";
import { useTheme } from "@mui/material/styles";

export default function TableCatalogButtons(props) {
  const { item, name, initialValues, handleDelete, formLayout, handleEdit } =
    props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [valuesCopy, setValuesCopy] = useState({});
  const deleteItem = () => {
    setOpenDeletePopup(true);
  };

  const parseProperty = (property) => {
    let splitProperty = property.split("_");
    let key = splitProperty[0];
    for (let i = 1; i < splitProperty.length; i++) {
      key +=
        splitProperty[i].charAt(0).toUpperCase() + splitProperty[i].slice(1);
    }
    return key;
  };

  const editItem = () => {
    let formValuesCopy = { ...initialValues };
    for (const property in item) {
      if (property.includes("_")) {
        let key = parseProperty(property);
        if (item[property] instanceof Object) {
          for (const subProperty in item[property]) {
            let subKey = parseProperty(subProperty);
            if (subKey !== "id")
              formValuesCopy[subKey] = item[property][subProperty];
          }
        } else {
          formValuesCopy[key] = item[property];
        }
      } else formValuesCopy[property] = item[property];
    }
    setValuesCopy({ ...formValuesCopy });
    setOpenEditPopup(true);
  };

  const popUps = () => (
    <>
      <Controls.Popup
        title={`Deleting a ${name}`}
        openPopup={openDeletePopup}
        setOpenPopup={setOpenDeletePopup}
      >
        <DeleteItemForm
          item={item}
          name={name}
          handleDelete={handleDelete}
          closePopup={() => {
            setOpenDeletePopup(false);
          }}
        />
      </Controls.Popup>
      <Controls.Popup
        title={`Editing a ${name}`}
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
      >
        <Form
          initialValues={valuesCopy}
          formLayout={formLayout}
          handleSubmit={handleEdit}
          openPopup={openEditPopup}
          buttons={Controls.CatalogEditButtons}
        />
      </Controls.Popup>
    </>
  );
  return (
    <>
      <Controls.ActionButton
        color="primary"
        sx={{
          flexShrink: 0,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        }}
        onClick={() => {
          return navigate(
            `/${
              name.toLowerCase() === "amplifier"
                ? "amplifier"
                : `calibration/${name.toLowerCase()}`
            }/${item.serial}`,
            {
              state: {
                item: item,
                name: name,
              },
            }
          );
        }}
      >
        <SpeedIcon />
      </Controls.ActionButton>
      <Controls.ActionButton
        color="success"
        onClick={editItem}
        sx={{
          flexShrink: 0,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        }}
      >
        <EditOutLinedIcon fontSize="small" />
      </Controls.ActionButton>
      <Controls.ActionButton
        color="error"
        onClick={deleteItem}
        sx={{
          flexShrink: 0,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        }}
      >
        <CloseIcon fontSize="small" />
      </Controls.ActionButton>
      {popUps()}
    </>
  );
}
