/**
 * Page listing all instruments and stands.
 * Allows CRUD functions for instruments and stands.
 */

import React, { PureComponent } from "react";
import Table from "../Table/Table";
import Controls from "../controls/Controls";
import {
  StandEditForm,
  standHeadCells,
  standTextFields,
} from "./InstrumConstants";
import { InstrumentAPIRouter } from "../Routers/InstrumentAPIRouter";
import Form from "../Form/Form";
import { nestedCopy } from "../controls/Constants";

export default class StandEdit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stands: null,
      totalStands: 0,
      loading: false,
      popup: false,
      generators: [],
      meters: [],
      initialValues: nestedCopy(StandEditForm.initialValues),
      formLayout: nestedCopy(StandEditForm.formLayout),
    };
    this.instrumentAPIRouter = new InstrumentAPIRouter();
  }

  togglePopup = () => {
    let formLayoutCopy = nestedCopy(StandEditForm.formLayout);
    formLayoutCopy[1].menuItems = nestedCopy(this.state.generators);
    formLayoutCopy[2].menuItems = nestedCopy(this.state.meters);
    this.setState({
      popup: !this.state.popup,
      initialValues: nestedCopy(StandEditForm.initialValues),
      formLayout: nestedCopy(formLayoutCopy),
    });
  };

  queryStands = async () => {
    await this.instrumentAPIRouter.getEditStands().then((results) => {
      this.setState({ loading: true });
      if (this._isMounted) this.handleDetail(results);
    });
  };

  handleDetail = (response, paging) => {
    if (!response || response.length === 0) {
      this.setState({
        stands: [],
      });
    } else {
      let formLayoutCopy = nestedCopy(this.state.formLayout);
      formLayoutCopy[1].menuItems = nestedCopy(response.generators);
      formLayoutCopy[2].menuItems = nestedCopy(response.meters);
      this.setState({
        stands: response.stands,
        generators: response.generators,
        meters: response.meters,
        formLayout: nestedCopy(formLayoutCopy),
        popup: false,
      });
    }
  };

  handleAdd = () => {
    console.log("Adding an instrument to stand.");
  };

  handleEdit = (item) => {
    this.changeFormLayout(item);
    this.setState({
      popup: true,
    });
  };
  handleDelete = () => {};

  handleSubmit = async (values) => {
    let updated_stand = {
      stand: values.stand,
      gener: values.gener.serial,
      meter: values.meter.serial,
    };
    await this.instrumentAPIRouter
      .updateStand(updated_stand)
      .then(this.queryStands);
  };

  changeFormLayout = (values) => {
    let formLayoutCopy = nestedCopy(this.state.formLayout);
    let initialValuesCopy = nestedCopy(this.state.initialValues);
    for (const value in values) {
      if (values[value] instanceof Object) {
        for (const form of formLayoutCopy) {
          if (form.menuItemName === value) {
            if (value === null) {
              form.defaultItem = values[value];
              break;
            }
            form.menuItems.push({ ...values[value] });
            initialValuesCopy[value] = values[value];
            form.defaultItem = { ...values[value] };
            break;
          }
        }
      }
    }
    this.setState({
      formLayout: nestedCopy(formLayoutCopy),
      initialValues: nestedCopy(initialValuesCopy),
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.instrumentAPIRouter.getEditStands().then((results) => {
      this.setState({ loading: true });
      if (this._isMounted) this.handleDetail(results);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.loading) this.setState({ loading: false });
  }

  render() {
    return !this.state.stands ? null : (
      <>
        <Table
          headCells={standHeadCells}
          tableItems={this.state.stands}
          tableButtons={Controls.StandEditButtons}
          name="Stands"
          handleAdd={this.handleAdd}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          tableCount={this.state.totalStands}
          disabled={false}
          searchByValue={"name"}
          textFields={standTextFields}
        />
        <Controls.Popup
          title={"Editing stand"}
          openPopup={this.state.popup}
          setOpenPopup={this.togglePopup}
        >
          <Form
            initialValues={this.state.initialValues}
            formLayout={this.state.formLayout}
            instruments={false}
            handleSubmit={this.handleSubmit}
            openPopup={this.state.popup}
            setOpenPopup={this.togglePopup}
            buttons={Controls.InstrumentSubmitButtons}
          />
        </Controls.Popup>
      </>
    );
  }
}
