/**
 *  Handles the screenshot component of other components.
 */
import { Grid } from "@mui/material";
import React from "react";
import * as htmlToImage from "html-to-image";
import Controls from "./Controls";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function ScreenShot(props) {
  const { children, headers, data, filename } = props;
  const ref = React.createRef(null);
  const CSVref = React.createRef();

  const createFileName = (extension = "", ...names) => {
    if (!extension) {
      return "";
    }

    return `${names.join("")}.${extension}`;
  };
  const takeScreenShot = async (node) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };
  const download = (image, { name = filename, extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const downloadCSV = () => {
    setTimeout(() => {
      CSVref.current.link.click();
    });
  };

  const downloads = () => (
    <Grid container justifyContent={"center"}>
      <Controls.Button
        onClick={downloadScreenshot}
        endIcon={<FileDownloadIcon />}
        size="small"
        text="Chart"
      />
      {headers ? (
        <>
          <Controls.Button
            text="CSV"
            endIcon={<FileDownloadIcon />}
            size="small"
            onClick={() => {
              downloadCSV();
            }}
          />
          <CSVLink
            headers={headers}
            data={data}
            filename={filename ? filename + ".csv" : "data.csv"}
            ref={CSVref}
          />
        </>
      ) : null}
    </Grid>
  );

  return (
    <>
      {downloads()}
      <div ref={ref}>
        <Grid container justifyContent="center">
          {children}
        </Grid>
      </div>
    </>
  );
}
