/**
 * Form component for power heads.
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { calibrationFactorTextFields } from "../Catalog/CataConstants";
import { nestedCopy } from "../controls/Constants";
import Controls from "../controls/Controls";

export default function PowerHeadForm(props) {
  const { values, errors, errorMessages, prevStep, handleSubmit } = props;

  const [calFactors, setCalFactors] = useState([]);
  const handleAddCalFactor = () => {
    setCalFactors([...calFactors, { frequency: "", factor: "", rho: "" }]);
  };

  const handleFactorChange = (e, index) => {
    const { name, value } = e.target;
    let factor_copy = nestedCopy(calFactors);
    factor_copy[index][name] = value;
    setCalFactors(nestedCopy(factor_copy));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h4">Calibration Factors</Typography>
      </Grid>
      {calFactors.map((factor, index) => {
        return (
          <Grid item>
            <Controls.TextFields
              fields={calibrationFactorTextFields}
              values={factor}
              fieldsName="calibrationFactorFields"
              handleInputChange={(e) => {
                handleFactorChange(e, index);
              }}
              errors={errors}
              errorMessages={errorMessages}
            />
          </Grid>
        );
      })}
      <Grid container justifyContent="center">
        <Grid item>
          <Controls.Button text="Add Cal Factor" onClick={handleAddCalFactor} />
        </Grid>
        <Grid item>
          <Controls.Button text="Go Back" onClick={prevStep} />
        </Grid>
        <Grid item>
          <Controls.Button
            text="Add"
            onClick={() => {
              handleSubmit({ ...values, calFactor: nestedCopy(calFactors) });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
