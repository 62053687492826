/**
 *  Buttons for the amplifier details table.
 */
import React from "react";
import Controls from "./Controls";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom/";

export default function TableAmplifierButtons(props) {
  const navigate = useNavigate();
  return (
    <Controls.ActionButton
      color="primary"
      onClick={() => {
        return navigate(
          `/test/${props.name.toLowerCase()}/${props.item.serial}`,
          {
            state: {
              testType: "compression",
              item: props.item,
            },
          }
        );
      }}
    >
      <PlayArrowIcon />
    </Controls.ActionButton>
  );
}
