/**
 * Buttons for calibration form.
 */
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Controls from "./Controls";

export default function CalibrationSubmitButtons(props) {
  const { values, validate, handleSubmit, setOpenPopup } = props;
  const [disableButton, setDisableButton] = useState(false);
  const disableSubmit = () => {
    if (validate(values)) {
      setDisableButton(true);
      handleSubmit(values);
      setOpenPopup(false)
    }
  };

  return (
    <Grid container justifyContent="center">
      <Controls.Button
        text={disableButton ? "Starting Calibration" : "Calibrate"}
        color="primary"
        variant="contained"
        disabled={disableButton}
        onClick={disableSubmit}
      />
    </Grid>
  );
}
