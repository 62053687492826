import React, { useEffect, useState } from "react";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import Table from "../Table/Table";
import { couplerHeadCells, newCouplerForm } from "../Catalog/CataConstants";
import Controls from "../controls/Controls";
import { changeFormLayout, nestedCopy } from "../controls/Constants";

export default function Couplers() {
  const [couplers, setCouplers] = useState(null);
  const [totalCouplers, setTotalCouplers] = useState(0);
  const [formLayout, setFormLayout] = useState(
    nestedCopy(newCouplerForm.formLayout)
  );
  const handleCouplers = (response) => {
    if (!response) {
      setCouplers([]);
      setTotalCouplers(0);
    } else {
      setCouplers(response);
      setTotalCouplers(response.length);
    }
  };

  const queryCouplers = async () => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter.getCatalogItems("couplers").then((response) => {
      handleCouplers(response);
    });
  };

  const handleEdit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newCoupler = {
      serial: values.serial,
      name: values.name,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      specified_attenuation: parseFloat(values.specifiedAttenuation),
    };
    return await catalogApiRouter
      .updateCatalogItem("couplers", values.id, newCoupler)
      .then(queryCouplers);
  };

  const handleSubmit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newCoupler = {
      name: values.name,
      serial: values.serial,
      specified_attenuation: parseFloat(values.specifiedAttenuation),
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
    };
    await catalogApiRouter
      .postCatalogItem("couplers", newCoupler)
      .then(queryCouplers);
  };

  const handleDelete = async (id) => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter
      .deleteCatalogItem("couplers", id)
      .then(queryCouplers);
  };

  useEffect(() => {
    let catalogApiRouter = new CatalogAPIRouter();
    catalogApiRouter.getCatalogItems("couplers").then((results) => {
      handleCouplers(results);
    });
    return () => {};
  }, []);

  return !couplers ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Table
      headCells={couplerHeadCells}
      tableItems={couplers}
      tableButtons={Controls.TableCatalogButtons}
      formButtons={Controls.CatalogButtons}
      initialValues={{ ...newCouplerForm.initialValues }}
      formLayout={formLayout}
      setFormLayout={setFormLayout}
      changeFormLayout={changeFormLayout}
      name="Coupler"
      handleSubmit={handleSubmit}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      tableCount={totalCouplers}
    />
  );
}
