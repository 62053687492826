/**
 *  Styling for a button group.
 */
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React from "react";
import { styled } from "@mui/material/styles";

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  outlineColor: "black",
  outlineWidth: "1px",
  outlineStyle: "solid",
  margin: "2px",
  maxHeight: "4.688rem",
}));

export default function ToggleButtons(props) {
  const { buttons, onChange, value } = props;
  return (
    <Grid container justifyContent="center">
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={onChange}
        size="small"
        aria-label="text alignment"
      >
        {buttons.map((button) => {
          return (
            <ToggleButton
              value={button.text}
              aria-label={`show ${button.text}`}
              key={button.text}
            >
              {button.icon}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Grid>
  );
}
