/**
 *  Formatting of exam control buttons.
 */
import Grid from "@mui/material/Grid";
import Controls from "./Controls";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";

export default function ExamControls(props) {
  const { inProcess, handleStop, handleContinue, stopped } = props;
  const [disableButton, setDisableButton] = useState(false);
  const disableStop = () => {
    setDisableButton(true);
    handleStop();
  };
  const enableStop = () => {
    setDisableButton(false);
    handleContinue();
  };
  return (
    <Grid container justifyContent="center">
      {inProcess ? (
        <>
          <Controls.Button
            text={disableButton ? "Stopping Exam" : "Stop Exam"}
            color="error"
            variant="contained"
            onClick={disableStop}
            disabled={disableButton}
            endIcon={<CircularProgress />}
          />
        </>
      ) : (
        <>
          {stopped || disableButton ? (
            <Controls.Button
              text="Continue"
              color="primary"
              variant="contained"
              onClick={enableStop}
            />
          ) : null}
        </>
      )}
    </Grid>
  );
}
