/**
 *  Handles styling for popups.
 */
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import Controls from "./Controls";
import Divider from "@mui/material/Divider";

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;
  return (
    <Controls.Dialog open={openPopup} maxWidth="md">
      <Controls.DialogTitle
        id="dialog-title"
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        {title}
      </Controls.DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Controls.Dialog>
  );
}
