/**
 * Styling for exam popups
 */
import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React from "react";
import Controls from "../controls/Controls";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  padding: theme.spacing.apply(2),
  position: "absolute",
  top: theme.spacing(5),
  color: "#BEBEBE",
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  paddingRight: "0px",
}));

export default function TestPopup(props) {
  const { title, children, openPopup, setOpenPopup } = props;

  return (
    <Dialog open={openPopup} maxWidth="lg">
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Controls.ActionButton
            color="error"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
