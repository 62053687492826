/**
 *  Websocket functions.
 */
export const openSocket = async (socket, timeout = 10000) => {
  const isOpened = () => socket.readyState === WebSocket.OPEN;
  if (socket.readyState !== WebSocket.CONNECTING) {
    return isOpened();
  } else {
    const intrasleep = 100;
    const ttl = timeout / intrasleep;
    let loop = 0;
    while (socket.readyState === WebSocket.CONNECTING && loop < ttl) {
      await new Promise((resolve) => setTimeout(resolve, intrasleep));
      loop++;
    }
    return isOpened();
  }
};

export const closeSocket = async (socket, timeout = 10000) => {
  socket.close();
  const isClosed = () => socket.readyState === WebSocket.CLOSED;
  if (socket.readyState !== WebSocket.CLOSING) {
    return isClosed();
  } else {
    const intrasleep = 100;
    const ttl = timeout / intrasleep;
    let loop = 0;
    while (socket.readyState === WebSocket.CLOSING && loop < ttl) {
      await new Promise((resolve) => setTimeout(resolve, intrasleep));
      loop++;
    }
    return isClosed();
  }
};

export const sendSocketMessage = (socket, value) => {
  if (socket) {
    socket.send(
      JSON.stringify({
        type: "message",
        message: value,
      })
    );
  } else {
  }
};
