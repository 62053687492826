import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { fullExamForm, examIcons, examSteps } from "./FullExamConstants";
import Box from "@mui/material/Box";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { nestedCopy } from "../controls/Constants";
import FullExamStep from "./FullExamStep";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {examIcons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function FullExam(props) {
  const { changeMessage, clearMessage } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [formLayout, setFormLayout] = React.useState(
    nestedCopy(fullExamForm.formLayout)
  );
  const [selections, setSelections] = React.useState({});
  const totalSteps = () => {
    return examSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has not been completed
        examSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // If false complete previous steps first before.
  const checkPrevious = (step) => {
    if (Object.keys(completed).length) {
      if (Object.keys(completed).length < step) return false;
      if (step < activeStep) return true;
      for (const complete of Object.keys(completed)) {
        if (!complete) return false;
      }
      return true;
    }
  };

  const showSelection = (step) => {
    let updatedSelections = { ...selections };
    for (const selection in selections) {
      if (selection === step) {
        updatedSelections[step].showChart = true;
      }
    }
    setSelections({ ...updatedSelections });
  };

  const handleStep = (step) => () => {
    if (step !== activeStep) {
      if (checkPrevious(step)) {
        showSelection(step);
        setActiveStep(step);
      }
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleSingleReset = () => {
    const newCompleted = completed;
    for (let step = activeStep; step > -1; step--) {
      newCompleted[`${activeStep}`] = false;
    }
    setCompleted(newCompleted);
  };

  console.log("completed steps", completed);
  const renderComponent = () => {
    return (
      <FullExamStep
        key={"fullExamStep"}
        changeMessage={changeMessage}
        clearMessage={clearMessage}
        selections={selections}
        setSelections={setSelections}
        label={examSteps[activeStep].label}
        tableTitle={examSteps[activeStep].tableTitle}
        query={examSteps[activeStep].query}
        queryParameters={examSteps[activeStep].queryParameters}
        charts={examSteps[activeStep].charts}
        lines={examSteps[activeStep].lines}
        columns={examSteps[activeStep].columns}
        checkBoxes={examSteps[activeStep].checkBoxes}
        tableHeadCells={examSteps[activeStep].tableHeadCells}
        getResults={examSteps[activeStep].getResults}
        formatResults={examSteps[activeStep].formatResults}
        setFormLayout={setFormLayout}
        handleComplete={handleComplete}
        handleSingleReset={handleSingleReset}
      />
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {examSteps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {step.label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {actStep + 1}
            </Typography> */}
            {renderComponent()}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== examSteps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
