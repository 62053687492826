/**
 * Quarter wave svg for login page.
 */
import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="300"
      version="1.1"
      viewBox="0 0 329.429 80.203"
    >
      <g strokeWidth="0.327" transform="translate(-12.02 -150.395)">
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M1071.34 202.088l-18.984.273 13.038 14.924 19.122 19.48 19.337 16.678 9.237 7.112 8.992 6.216 9.125 5.565 9.63 5.158 14.454 6.707 14.678 5.297 22.093 5.227 18.002 2.226 15.196.25 16.027-.742 2.953-.67.846-1.525-.174-1.012-.822-.66-5.408-1.028-20.375-3.59-18.922-4.943-17.426-6.287-15.893-7.617-14.853-9.06-14.21-10.305-10.37-8.823-10.586-10.084-10.563-11.109-10.304-11.896-4.746-5.74z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#cbd9f2"
          fillOpacity="1"
          d="M780.793 43.69l-2.322.001-8.51.614-21.24 2.64-24.711 5.344-4.092 1.18-16.53 5.441-11.868 4.813-10.715 5.064-5.678 2.996-2.848 2.301-9.804 11.258 20.283.2h20.281l1.074-.929 12.86-10.084 10.304-7.035 10.584-6.347 10.63-5.53 10.435-4.576 13.69-4.726 7.562-2.02 1.111-.354z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M22.809 16.234l-16.725.684-5.158.752-.77.664L0 19.438l.213.95.836.669 4.715 1.015 20.1 3.645 17.995 4.713 13.04 4.498 12.195 5.119 11.252 5.695 10.2 6.22 2.57 1.5 1.261-1.878 8.51-12.684 1.648-2.199.008-.789-14.652-6.894-19.192-6.534-12.304-2.923-12.409-2.094-8.017-.844z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M189.32 157.059h39.526l-23.66-30.112-17.71-21.134-14.783-16.499-13.232-13.328-13.065-11.625-3.789-3.175-1.263 1.459-4.381 6.27-3.613 7.183-2.754 8.181 4.01 4.508 11.986 13.084 12.904 15.484 11.822 15.155z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M306.58 157.059l-.12-1.64-.095-27.245-.004-31.504h-18.027l.123 33.305.187 27.084z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M368.977 157.059l-.098-20.838V96.67h-17.977l-.109 29.703-.187 26.191-.461 4.495z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M368.977 157.059h-18.832l-.174 1.7-1.508 4.33-2.568 4.02-3.47 3.366-4.265 2.629-4.959 1.814-7.564.791-7.317-.795-4.326-1.762-3.27-2.564-2.304-3.47-1.438-4.48-.402-5.58h-17.935l.006.944.462 7.73 2.278 7.235 3.63 5.804 4.28 4.135 5.336 3.156 6.392 2.172 7.444 1.188 11.154-.631 6.291-1.682 5.422-2.773 4.57-3.88 3.746-5.005 1.141-1.9.133 7.137.387 7.546 9.123.41h8.869l-.215-6.435z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M453.912 157.059l.047-22.42.143-19.475-.752-2.29-3.108-6.712-2.031-2.55-2.455-2.174-5.344-3.17-6.392-2.317-7.293-1.418-8.05-.48-6.437.277-5.851.828-5.248 1.367-4.625 1.903-3.989 2.422-3.332 2.94-2.658 3.442-1.967 3.94-1.945 9.31v1.065h17.3l.206-1.883.904-3.84 1.569-3.28 2.628-2.87 3.354-1.992 4.195-1.162 5.147-.38 5.82.47 4.822 1.398 3.756 2.293 2.617 3.158 1.594 4.691.66 7.65.182 5.73h-11.094l-15.183.591-8.475 1.652-6.863 2.356-5.66 3.242-4.875 4.315-4.647 6.318-2.594 7.055h17.508l1.102-2.655 1.785-2.556 3.125-2.688 4.166-2.232 5.025-1.698 5.701-1.085 12.303-.534 8.444.6-.86 12.848z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M453.912 157.059h-17.625l-.121 1.81-.908 3.756-1.428 3.395-1.959 3.056-2.504 2.74-5.097 3.553-5.967 1.81v-.001l-5.076.287-5.026-.317-4.053-1.275-3.628-1.986-2.245-2.164-1.699-2.557-1.148-2.84-.582-3.01-.006-3.064.58-3.008.076-.185h-17.508l-.154.418-.682 3.63.014 4.135.7 5.856 1.896 5.035 2.486 4.017 2.885 3.26 3.611 2.62 4.328 2.247 4.684 1.721 4.672 1.031 7.502.178 7.103-.414 5.643-1.746 5.02-2.694 4.345-3.613 3.623-4.498 1.785-2.709.211 3.201 1.315 8.846.326 1.064h7.646l7.844-.316-.342-5.156-.56-14.453z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M485.121 157.059l.166-24.875.865-3.332 2.077-5.811 2.93-4.389 3.97-3.172 5.2-2.158 3.235-.634 5.895-.133 7.611.226 1.063.239V96.08l-1.98-.23-4.204-.092-4.865.502-4.838.99-4.127 1.371-4.287 2.274-3.514 2.824-2.84 3.472-2.251 4.21-.78 1.798-.015-3.303-.229-8.263-.213-4.963h-17.613l.187 1.392.676 13.303.158 36.686.051 9.008z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M485.121 157.059H467.45l.174 31.373 8.756.136 8.533-.088.188-28.148z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M553.875 157.059l-.066-18.854v-29.098h20.634l-.088-6.3-.09-6.301-10.228-.086-10.228-.086V71.75l-.737.213-15.3 4.889-1.147.433-.086 9.524-.088 9.523-8.095.088-8.096.088V108.945l8.084.088 8.084.086.103 33.299.045 14.64z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M553.875 157.059h-17.299l.059 18.658.9 2.67 2.336 4.613 3.555 3.346 4.922 2.177 6.443 1.108 10.617-.008 8.285-1.016.641-1.08.096-5.758v-6.562l-2.21.254-6.464.254-6.055-.655-3.27-2.109-1.945-3.457-.588-5.764z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M622.412 94.535l-6.805.16-4.007.6-5.934 1.678-5.135 2.263-4.576 2.98-4.262 3.825-3.99 4.7-3.082 5.404-3.201 8.52-1.611 9.964-.45 10.15.588 9.29.578 2.99h17.118l-.122-.454-.746-6.666-.173-4.171h24.642v-12.112H597.34l.008-.736.47-4.174 1.807-7.14 3.281-5.827 4.717-4.459 6.11-3.039 5.148-1.119 3.879-.082z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M621.244 94.598v12.513l3.125-.066 5.283.846 4.537 1.775 4.106 3.213 3.098 4.44 2.039 5.566.931 6.597h-.002l.211 4.174-24.149-.031c.001-.145-.063 8.042-.063 12.08l9.179.063h32.938l-.012-4.829-.295-8.521-.895-7.21-1.562-6.278-2.303-5.73-4.004-6.432-5.213-5.041-6.629-3.852-8.087-2.572-4.012-.596-6.922-.13z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M597.643 157.059h-17.118l1.036 5.357 2.63 7.324 2.332 4.332 2.774 3.858 3.21 3.379 3.643 2.898 4.073 2.416 4.498 1.932 4.916 1.441 5.334.95h.002l8.937.161 8.772-.826 6.078-1.658 5.312-2.268 4.649-2.93 4.088-3.646 4.402-5.68 2.797-6.26 1.482-7.915-8.62-.41h-8.628l-.767 3.191-2.04 5.711-3.324 4.354-3.625 2.906-4.412 1.857-2.91.639-4.619.137-4.63-.135-3.061-.62-5.274-2.181-4.394-3.24-3.5-4.28-2.586-5.3z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M696.713 157.059l.557-25.858 1.793-6.584 2.939-5.06 4.232-3.711 5.664-2.533 3.13-.645 6.036-.121 7.774.236 1.062.237V96.08l-1.959-.226-8.54.316-8.643 1.984-4.479 2.207-3.898 2.973-3.147 3.574-2.22 4.016-.723 1.781-.215-4.96-.215-8.016v-3.06H678.09l.228 3.192.672 17.069.168 34.974h-.002l.002 5.155z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#626262"
          fillOpacity="1"
          d="M696.713 157.059h-17.555l.01 31.586H696.47l.15-27.249z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M755.021 157.059h17.452l-2.325-7.692-3.267-10.8-3.254-10.801-2.615-8.674-2.637-8.674-2.396-7.855-1.735-5.729-9.553-.086-9.552.064 5.51 16.782z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M795.918 157.059h-15.941l-1.657 5.238-1.394 4.3-.76 2.337-.715-2.172-2.681-8.721-.297-.982H755.02l7.297 22.068 3.133 9.25v.002l10.35.19 10.18-.088.248-.819 4.94-15.709 1.268-3.928 2.6-8.183z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M839.19 157.059l-1.506-5.237-.74-2.619-1.32-4.582-1.311-4.582-1.31-4.582-1.308-4.582-1.265-4.42-1.807-6.219-3.52-12.11-2.59-8.919-.753-2.537h-22.724l-.216.9-4.746 15.139-3.947 12.437-2.61 8.184-1.974 6.219-1.975 6.219-2.607 8.181-.984 3.11h15.941l1.758-5.563 1.965-6.219 1.922-6.056 1.972-6.219 1.985-6.219 1.964-6.219L809.27 115l.425-1.145.52 1.8 10.187 35.677 1.635 5.727z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M862.088 157.059l.978-2.782 2.83-8.02 1.956-5.564 1.962-5.564 1.965-5.563 1.963-5.564 1.965-5.564 1.955-5.565 2.14-6.055 3.442-9.58-.08-.318-1.043-.162-7.726-.01-9.088.086-1.223 3.764-3.24 10.148-2.617 8.182-1.967 6.218-1.98 6.22-2.618 8.183-2.601 8.181-1.98 6.22-.993 3.109z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M862.088 157.059h-16l-1.627 5.105-1.47 4.861-.536.674-.893-2.457-2.183-7.527-.19-.656h-17.152l8.527 29.867.495 1.719h9.972l9.975-.098.996-2.863 1.441-4.077 4.606-13.091 1.957-5.565 1.101-3.11z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M952.982 157.059l.133-15.424.153-23.108-.489-4.574-1.011-3.256-1.83-3.797-2.405-3.177-3.1-2.686-3.91-2.322-4.388-1.858-4.8-1.377-10.942-1.334-7.207.073-6.508.728-5.79 1.371-5.056 2.01-4.307 2.637-3.539 3.258-2.754 3.869-1.953 4.472-1.502 8.247-.004.736h17.348v-.914l1.777-6.49 1.504-2.428 2.1-2.053 2.64-1.635 3.127-1.175 6.895-.69 7.158.733 5.494 2.189 3.809 3.633 2.101 5.064.567 7.694.215 6.054h-11.084l-15.783.666-5.774 1.002-5.598 1.594-6.716 3.092-5.797 4.398-4.6 5.444-3.119 6.224-.703 3.11h17.355l.014-.065 1.63-3.51 2.495-2.916 3.459-2.414 4.525-2 4.965-1.297 6.194-.884 13.464-.276 4.764.453-.11 6.2-.486 6.658-.01.05z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M952.982 157.059h-17.388l-.99 5.087-1.665 4.122-2.468 3.61-2.676 2.683-3.11 2.136-3.472 1.553-3.764.93-5.344.287-5.197-.45-3.428-1.038-2.982-1.618-2.486-2.113-1.944-2.533-1.351-2.873-.71-3.13-.02-3.31.702-3.343h-17.355l-.922 4.082.117 7.058 1.813 6.614 3.467 5.857 3.92 3.746 4.511 3.043 5.53 2.168 5.804 1.42 7.856.062 6.27-.21 3.386-.778 4.812-1.978 4.245-2.663 3.703-3.367 3.195-4.097 1.719-2.604v1.625l1.185 9.563.524 2.045H954.2l-.228-2.538-.53-5.154-.55-13.54z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M999.209 157.059l-.586-1.473-1.271-3.191-3.47-8.592-17.58-43.696-.974-2.537-.336-.9h-9.623l-9.623.41 3.34 8.428 2.394 5.728 2.624 6.22 2.117 5.071 1.968 4.746 2.612 6.22 2.607 6.218 4.44 10.639 2.123 5.072.683 1.637z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M1025.188 157.059l4.812-10.965 10.031-22.912 5.238-11.946 3.237-7.42 2.814-6.796-9.545-.272-9.545.086-.96 2.455-2.704 6.873-3.271 8.348-3.274 8.345-3.212 8.182-3.985 10.149-3.338 8.51-2.886 7.363z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M1025.188 157.059H1008.6l-2.584 6.587-1.836 5.073-.928-1.662-3.357-8.28-.686-1.718h-18.555l1.78 4.255 2.619 6.22 2.113 5.071 2.113 5.075 2.498 6.002 1.625 3.687.657 1.287 8.68-.088 8.679-.088 1.432-3.273 6.224-14.238 5.25-11.946z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M1090.965 94.475l-.34-.012-8.492.836-7.346 2.031-6.434 3.318-5.753 4.696-4.317 4.867-3.238 5.342-2.635 6.326-1.697 6.992-.842 8.172-.078 9.87.69 9.685.109.46h17.394l-1.144-7.64-.232-3.65h24.355v-12.112h-23.281l.012-1.39.326-4.213.896-3.967 1.43-3.672 1.936-3.33 2.406-2.943 2.844-2.506 3.252-2.024 3.623-1.494 4.85-.984 1.706-.031z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="0.941"
          d="M1090.965 94.475v12.627l3.496-.065 5.049.756 4.38 1.578 3.202 2.168 2.718 2.961 2.19 3.685 1.605 4.342 1.213 9.832v1.297h-23.851v12.112h41.437l.215-2.374-.152-8.955-.98-9.293-1.696-6.628-2.312-5.672-3.006-4.866-3.782-4.218-5.851-4.383-6.83-2.969-8.006-1.619z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M1067.986 157.059h-17.394l1.842 7.757 3.123 7.291 4.49 6.405 5.6 5.226 6.456 3.756 6.702 2.207 7.047 1.354 7.527.04 10.598-.997 4.458-1.217 4.419-1.805 6.884-4.246 5.473-5.514 3.873-6.543 2.092-7.332.521-3.197.157-.74-8.754.086-8.756.088-.233 1.363-.91 3.557-1.957 4.545-2.809 3.646-3.705 2.793-4.642 1.982-2.887.625-4.476.123-6.067-.396-5.062-1.61-3.133-1.644-2.773-2.148-2.41-2.647-2.042-3.139-2.959-7.718z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="0.985"
          d="M268.92 157.059h-30.936l-.468 1.804-2.21 6.213-4.595-5.644-1.865-2.373H189.32l1.56 2.128 18.177 24.778 5.843 8.055-1.212 1.334-4.045 2.287-8.125 3.26-9.223 2.14-9.1.771-9.556-.068-7.352-.943-7.084-1.698-6.535-2.367-5.693-2.95-5.42-3.962-4.455-4.166-6.94-9.584-5.38-11-2.483-8.015H91.096l4.148 14.6 5.697 12.788 7.399 11.397 5.617 6.375 6.002 5.629 9.598 6.593 10.554 5.086 11.729 3.666 13.125 2.33 14.178.604 14.296-.592 9.479-1.512 8.875-2.209 8.17-2.878 7.353-3.514 4.516-2.205 11.813 13.722 12 12.48 12.095 11.628 11.633 9.752 11.65 8.578 11.875 7.545 12.301 6.65 12.274 5.582 10.853 4.076 11.537 3.487 11.717 2.761 11.389 1.899 5.402.701 8.346.613v.002l16.037.438 15.549-.418 7.773-.483.408-.287-2.863-1.027-24.139-7.69-14.271-5.671-16.848-7.887-17.814-9.518-7.56-4.013-11.544-6.352-9.312-5.842-10.395-7.603-9.279-7.455-8.963-7.987-9.45-9.195-12.07-12.492-12.007-13.668-4.887-5.703 1.272-1.918 6.398-12.205 1.312-3.274 2.579-7.207z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#cbd9f2"
          fillOpacity="1"
          d="M577.744 202.064l-3.539 4.336-9.068 10.666-9.21 9.965-9.118 9.026-8.801 7.847-14.387 10.899-14.326 9.377-15.334 7.722-16.068 6.05-16.692 4.341-17.205 2.598-9.781.595-10.817.041-10.85-.492-9.884-1.006-3.809-.406-.105.186.895.457 17.84 6.177 4.908 1.389 24.384 5.773 4.172.655 14.813-3.389 3.927-1.174 12.68-4.172 12.168-4.953 10.203-4.87 9.965-5.401 9.78-5.969 9.648-6.576 19.111-15.123 19.22-18.006 15.75-16.49 8.472-9.705-9.471-.368z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="0.977"
          d="M181.33 12.879l-10.547.428-9.84 1.304-9.298 2.213-8.926 3.15-10.184 5.003-9.203 6.189-8.203 7.344-7.18 8.472-6.138 9.57-5.075 10.641-3.99 11.684-2.883 12.695-1.814 13.713-.455 16.098.129 11.387.615 7.433 2.756 16.848.002.008h31.2l-1.444-4.67-2.579-14.64-.66-18.33.66-18.329 1.43-9.228 1.92-8.458 2.42-7.705 2.93-6.97 3.447-6.256 3.975-5.563 4.513-4.886 5.059-4.23 5.914-3.759 6.428-2.931 7.142-2.184 8.059-1.51 5.422-.552 6.69.013 9.796.68 8.37 1.818 7.839 2.922 7.025 4.026 6.207 5.123 5.385 6.218 4.565 7.309 3.734 8.395 2.908 9.478 2.072 10.557 1.198 10.804.306 12.926-.632 16.768-2.202 14.654-2.209 8.51h30.936l.057-.205 1.865-8.649 1.17-8.002.68-5.728.517-13.975-.203-15.156-1.227-11.076-1.8-10.262-2.393-9.49-2.998-8.764-3.623-8.078-4.266-7.441-4.926-6.844-5.601-6.293-9.703-8.363-11.115-6.53-8.458-3.636-8.443-2.543-13.148-2.407z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
        <path
          fill="#00a0e1"
          fillOpacity="1"
          d="M827.098.064L813.574.78l-13.219 1.537-13.501 2.414-10.479 2.493-10.797 3.367-10.72 3.79-8.1 3.58-7.502 3.429-4.752 2.619-4.774 2.619-7.302 4.402-11.563 7.909-11.9 9.318-6.444 5.787 1.897-.596 6.353-2.424 16.225-5.382 16.043-4.155 16.764-3.127 18.394-2.3 9.069-.62 16.297-.115 16.287.125 9.406.604 19.484 2.244 17.176 2.906 5.89 1.154 16.018 3.87 14.914 4.86 15.057 6.647 15.207 8.496 10.102 6.743 10.13 7.742 6.086 4.828h26.778l26.779-.203-7.82-9.104-18.213-18.394-11.082-9.813-11.498-9.045-11.496-7.97-11.08-6.588L919.47 18.3 907.63 13.1l-15.385-5.182-4.418-1.297-4.582-1.127-6.219-1.303-16.203-2.593-7.199-.834-12.111-.637z"
          transform="matrix(.26458 0 0 .26458 12.02 150.395)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
