/**
 * Form component for missing calibrations during exams.
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Controls from "../controls/Controls";

export default function MissingTestForm(props) {
  return (
    <>
      {!props.missingCableResults ? null : (
        <>
          <Typography variant="h5">
            These are the uncalibrated frequencies for the cable.
          </Typography>
          <Typography variant="h6">
            {props.missingCableResults.map((frequency) => {
              return `${frequency} `;
            })}
          </Typography>
        </>
      )}
      {!props.missingCouplerResults ? null : (
        <>
          <Typography variant="h5">
            These are the uncalibrated frequencies for the coupler.
          </Typography>
          <Typography variant="h6">
            {props.missingCouplerResults.map((frequency) => {
              return `${frequency} `;
            })}
          </Typography>
        </>
      )}
      <Typography variant="h5">
        Do you want to calibrate these frequencies?
      </Typography>
      <Grid container justifyContent="center">
        <Controls.Button
          text="Calibrate"
          color="primary"
          variant="contained"
          onClick={() => {
            props.setOpenPopup(false);
          }}
        />
        <Controls.Button
          text="Cancel"
          color="secondary"
          variant="contained"
          onClick={() => {
            props.setOpenPopup(false);
          }}
        />
      </Grid>
    </>
  );
}
