/* Allows for easy reuse of Material UI Button component with only an action. */
import MuiButton from "@mui/material/Button";
import React from "react";
import { styled } from "@mui/material/styles";

export const Button = styled(MuiButton)(({ theme }) => ({
  minWidth: 0,
  margin: theme.spacing(0.5),
  [theme.breakpoints.down("sm")]: {
    minWidth: 20,
    paddingLeft: 2,
    paddingRight: 2,
  },
}));

export default function ActionButton(props) {
  const { color, children, onClick } = props;
  return (
    <Button variant="contained" color={color} onClick={onClick}>
      {children}
    </Button>
  );
}
