/**
 *  Styling for expanding ext field.
 */
import Grid from "@mui/material/Grid";
import React from "react";
import { TextareaAutosize, TextField, Tooltip } from "@mui/material";

export default function TextFieldResize(props) {
  const {
    fields,
    fieldsName,
    handleInputChange,
    errors,
    errorMessages,
    disabled,
    values,
  } = props;
  if (fields)
    return (
      <Grid container spacing={1} justifyContent="center">
        {fields.map((field, index) => {
          return (
            <Grid item key={`${fieldsName}${index}`}>
              <Tooltip followCursor title={field.toolTip} arrow>
                <TextField
                  placeholder={field.placeholder}
                  label={field.label}
                  variant="outlined"
                  name={field.id}
                  value={values ? values[field.id] : ""}
                  onChange={handleInputChange}
                  error={errors[field.id]}
                  disabled={disabled}
                  InputProps={{
                    inputComponent: TextareaAutosize
                  }}
                  helperText={
                    errorMessages[field.id] === ""
                      ? ""
                      : errorMessages[field.id]
                  }
                />
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    );
  else return null;
}
//
