/* Allows for easy reuse of Material UI Button component */
import MuiButton from "@mui/material/Button";
import React from "react";
import { styled } from "@mui/material/styles";

export const ButtonStyle = styled(MuiButton)(({ theme }) => ({
  margin: theme.spacing(0.5),
  maxWidth: "300px",
  maxHeight: "50px",
  textTransform: "none",
}));

export default function Button(props) {
  const { text, size, color, variant, onClick, ...other } = props;
  return (
    <ButtonStyle
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
    >
      {text}
    </ButtonStyle>
  );
}
