/**
 * CURRENTLY NOT IN USE. Handles details of all instruments in db.
 */
import React, { PureComponent } from "react";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Cables from "../Cables/Cables";
import Couplers from "../Couplers/Couplers";
import Amplifiers from "../Amplifiers/Amplifiers";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import MemoryIcon from "@mui/icons-material/Memory";
import { GiAmplitude } from "react-icons/gi/";
import Controls from "../controls/Controls";

export default class Catalog extends PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      testEquip: [],
      viewTable: "cable",
    };
    this.catalogApiRouter = new CatalogAPIRouter();
  }

  handleDetail = async (response) => {
    if (!response || response.length === 0) this.setState({ testEquip: [] });
    else this.setState({ testEquip: response });
  };

  componentDidMount() {
    this._isMounted = true;
    this.catalogApiRouter.getTestEquip().then((data) => {
      if (this._isMounted) this.handleDetail(data);
    });
  }

  handleTableChange = (event, newTable) => {
    if (newTable && this.state.viewTable !== newTable) {
      this.setState({ viewTable: newTable });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    var equipment = [];
    for (var key in this.state.testEquip) {
      equipment.push(key);
    }
    var buttons = [
      { text: "cable", icon: <SettingsInputComponentIcon /> },
      { text: "coupler", icon: <MemoryIcon /> },
      { text: "amplifier", icon: <GiAmplitude size={25} /> },
    ];
    return !this.state.testEquip ? null : (
      <>
        <Controls.ToggleButtons
          value={this.state.viewTable}
          onChange={this.handleTableChange}
          buttons={buttons}
        />

        {this.state.viewTable === "cable" ? (
          <Cables />
        ) : this.state.viewTable === "coupler" ? (
          <Couplers />
        ) : (
          <Amplifiers />
        )}
      </>
    );
  }
}
