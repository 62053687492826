/**
 *  Styling for multiple textfields.
 */
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

const CssTextField = styled(TextField)(({ theme }) => ({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
}));

export default function TextFields(props) {
  const {
    fields,
    fieldsName,
    handleInputChange,
    errors,
    errorMessages,
    disabled,
    size,
    values,
  } = props;
  return (
    <Grid container spacing={1} justifyContent="center">
      {fields.map((field, index) => {
        let fieldSeparation = field.label.split("|");
        return (
          <Grid item key={`${fieldsName}${index}`}>
            <Tooltip followCursor title={field.toolTip} arrow>
              <CssTextField
                label={fieldSeparation[0]}
                variant="outlined"
                name={field.id}
                InputLabelProps={{ shrink: true }}
                value={values ? values[field.id] : ""}
                onChange={handleInputChange}
                error={errors[field.id]}
                disabled={disabled}
                helperText={
                  errorMessages[field.id] === "" ? "" : errorMessages[field.id]
                }
                size={size}
                InputProps={{
                  endAdornment:
                    fieldSeparation.length > 1 ? (
                      <InputAdornment position="end">
                        {fieldSeparation[1]}
                      </InputAdornment>
                    ) : null,
                }}
                style={{ width: "100%" }}
              />
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
}
