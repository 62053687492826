import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { useNavigate } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ExamCard(props) {
  const { item } = props;
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const displayExam = () => {
    if (item.type.toLowerCase().includes("calibration")) {
      return navigate(
        `/calibration/${item.type.toLowerCase().split(" ")[0]}/${
          item.coupler ? item.coupler.serial : item.cable.serial
        }`,
        {
          state: {
            item: item.coupler || item.cable,
            name: item.type.split(" ")[0],
            calibration: item,
          },
        }
      );
    } else if (item.type.toLowerCase().includes("saturation")) {
      return navigate(
        `/test/${item.type.toLowerCase().split(" ")[0]}/${item.id}`,
        {
          state: {
            item: item,
            active: item.is_active,
            name: item.type.split(" ")[0],
          },
        }
      );
    } else if (item.type.toLowerCase().includes("burn")) {
      return navigate(
        `/test/${item.type.toLowerCase().split(" ")[0]}/${item.id}`,
        {
          state: {
            item: item,
            active: item.is_active,
            name: item.type.split(" ")[0],
          },
        }
      );
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        title={`${item.type}`}
        subheader={`Started: ${
          item.date_started
            ? new Date(item.date_started).toLocaleString()
            : "N/A"
        }`}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {item.date_done
            ? `Completed: ${new Date(item.date_done).toLocaleString()}`
            : "Incomplete"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`Performed by: ${item.user ? item.user : "N/A"}`}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <IconButton onClick={displayExam} aria-label="display exam">
          <SmartDisplayIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph></Typography>
          <Typography paragraph></Typography>
          <Typography paragraph></Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
