/**
 *  Buttons for the exam results table.
 */
import React from "react";
import Controls from "./Controls";
import ShowChartIcon from "@mui/icons-material/ShowChart";

export default function TableFullExamButtons(props) {
  const { item, handleChartChange, handleSubmit } = props;
  return (
    <>
      <Controls.ActionButton
        color="primary"
        onClick={() => {
          handleChartChange(item);
        }}
      >
        <ShowChartIcon fontSize="small" />
      </Controls.ActionButton>
      <Controls.Button text="Select" onClick={() => handleSubmit(item)} />
    </>
  );
}
