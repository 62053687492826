/**
 * Page listing all saturation exams. Allows CRUD functions for saturation exams and
 * redirect to saturation exams results.
 */
import React, { useEffect, useRef, useState } from "react";
import ExamAPIRouter from "../Routers/ExamAPIRouter";
import TestPopup from "./TestPopup";
import MissingTestForm from "../Form/MissingTestForm";
import Controls from "../controls/Controls";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "../Table/Table";
import {
  formatPowerResults,
  formatSaturationResults,
  powerExamHeaders,
  satExamHeaders,
  saturationExamForm,
  saturationExamHeadCells,
} from "./ExamConstants";
import AmplifierStatus from "../Amplifiers/AmplifierStatus";
import { MissingCalibrations } from "../Calibration/MissingCalibrations";
import {
  compPointDot,
  compressionCheckboxes,
  PowExamLines,
  SatExamLines,
  satPointDot,
  saturationExamCheckboxes,
} from "../Chart/ChartConstants";
import { openSocket, sendSocketMessage } from "../controls/WebSocket";
import { connect } from "react-redux";
import Chart from "../Chart/Chart";
import {
  changeFormLayout,
  standButton,
  handleContinue,
  handleStop,
  joinExam,
  nestedCopy,
  setUpSocket,
  updateTimer,
  MAX_PRECISION,
} from "../controls/Constants";
import { Divider, Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import PowerHeadSelection from "../PowerHeads/PowerHeadSelection";

function SaturationTest(props) {
  const { changeMessage, clearMessage, user } = props;
  const location = useLocation();
  let currGainData = useRef([]);
  let _initialFormValues = useRef({ ...saturationExamForm.initialValues });
  let _initialFormLayout = useRef(nestedCopy(saturationExamForm.formLayout));
  let allPreviousGainTests = useRef([]);
  let currGainChart = useRef({});
  let chartIndex = useRef(-1);
  let saturationTestParams = useRef(null);
  let socket = useRef(null);
  let item = useRef(location.state ? location.state.item : null);
  let missingCableResults = useRef(null);
  let missingCouplerResults = useRef(null);
  if (item.current) {
    _initialFormLayout.current[1].defaultItem = { ...item.current.amplifier };
    _initialFormLayout.current[1].disabled = true;
    _initialFormValues.current.amplifier = { ...item.current.amplifier };
    _initialFormLayout.current[2].defaultItem = { ...item.current.cable };
    _initialFormLayout.current[2].disabled = true;
    _initialFormValues.current.cable = { ...item.current.cable };
    _initialFormLayout.current[3].defaultItem = { ...item.current.coupler };
    _initialFormLayout.current[3].disabled = true;
    _initialFormValues.current.coupler = { ...item.current.coupler };
  }
  const [loading, setLoading] = useState(false);
  const [satExams, setSatExams] = useState(null);
  const [totalSatExams, setTotalSatExams] = useState(0);
  const [satData, setSatData] = useState([]);
  const [currentGainData, setCurrentGainData] = useState([]);
  const [previousGainData, setPreviousGainData] = useState([]);
  // const [initialValues, setInitialValues] = useState({
  //   ..._initialFormValues.current,
  // });
  const [formLayout, setFormLayout] = useState(
    nestedCopy(_initialFormLayout.current)
  );
  const [cable, setCable] = useState(null);
  const [coupler, setCoupler] = useState(null);
  const [amplifier, setAmplifier] = useState(null);
  const [inProcess, setInProcess] = useState(
    location.state ? (location.state.active ? true : false) : false
  );
  const [missingCalibrations, setMissingCalibrations] = useState(false);
  const [resetGainChart, setResetGainChart] = useState(false);
  const [resetSatChart, setResetSatChart] = useState(false);
  const [newExam, setNewExam] = useState(false);
  const [openSelection, setOpenSelection] = useState(false);
  const [unitStatus, setUnitStatus] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [viewUnitStatus, setViewUnitStatus] = useState(false);
  const [stopExam, setStopExam] = useState(
    location.state && typeof location.state.active === "boolean"
      ? !location.state.active
      : false
  );
  const [examMetaData, setExamMetaData] = useState(null);
  const [currentFrequency, setCurrentFrequency] = useState(null);
  const [closed, setClosed] = useState(null);
  const navigate = useNavigate();

  const redirectExam = () => {
    if (location.state && location.state.item)
      return navigate(location.pathname, {
        state: {
          item: location.state ? location.state.item : null,
          name: location.state.name,
        },
      });
  };

  const clear = (repeat) => {
    if (!repeat) {
      redirectExam();
      setStopExam(false);
      setResetGainChart(false);
      setResetSatChart(false);
      setSatData([]);
      setPreviousGainData([]);
      setInProcess(false);
      setUnitStatus(null);
      setStatusHistory([]);
      setExamMetaData(null);
      chartIndex.current = -1;
      allPreviousGainTests.current = [];
    }
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }
    setCurrentGainData([]);
    currGainData.current = [];
    clearMessage();
  };

  const fetchSaturationExams = async () => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.getExams("sat_exams").then((data) => {
      handleSaturationExams(data);
    });
  };

  const handleViewUnitStatus = () => {
    setViewUnitStatus(!viewUnitStatus);
  };

  const handleResetGainChart = () => {
    setResetGainChart(!resetGainChart);
  };

  const handleResetSaturationChart = () => {
    setResetSatChart(!resetSatChart);
  };
  const handleSaturationExams = (response, paging) => {
    if (!response || response.length === 0) {
      setSatExams([]);
    } else {
      setTotalSatExams(response.length);
      setSatExams(response);
    }
  };

  const handleDelete = async (id) => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.deleteSaturationExam(id).then(fetchSaturationExams);
  };

  const handleChartChange = async (pastExam) => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.getExamResults("sat_exams", pastExam.id).then((res) => {
      if (res && res.results) {
        let formattedResults = formatSaturationResults(res.results);
        setSatData(formattedResults);
        handleResetSaturationChart();
        setExamMetaData({ ...pastExam });
        setCurrentFrequency(res.results[0].frequency);
        setUpPowerExams(res.results);
        setUnitStatus(
          res.unit_status
            ? res.unit_status.length
              ? res.unit_status[res.unit_status.length - 1]
              : null
            : null
        );
        setStatusHistory(res.unit_status);
        clearMessage();
      } else {
        changeMessage("No Results Found.", "red");
      }
    });
  };

  const setUpPowerExams = (powerExams) => {
    allPreviousGainTests.current = nestedCopy(powerExams);
    chartIndex.current = 0;
    if (powerExams.length) getPowerExam(powerExams[0].id, 0);
  };

  const getPowerExam = async (powerExamId, index) => {
    let examAPIRouter = new ExamAPIRouter();

    await examAPIRouter
      .getExamResults("power_exams", powerExamId)
      .then((results) => {
        if (results) {
          let formattedResults = formatPowerResults(results);
          allPreviousGainTests.current[index].results = nestedCopy(
            formattedResults
          );
          setPreviousGainData(allPreviousGainTests.current[index].results);
          handleResetGainChart();
        }
      });
  };

  const changeView = (offset) => {
    if (
      chartIndex.current + offset > -1 &&
      chartIndex.current + offset < allPreviousGainTests.current.length
    ) {
      chartIndex.current += offset;

      if (!allPreviousGainTests.current[chartIndex.current].results) {
        getPowerExam(
          allPreviousGainTests.current[chartIndex.current].id,
          chartIndex.current
        );
      } else {
        setPreviousGainData(
          allPreviousGainTests.current[chartIndex.current].results
        );
        handleResetGainChart();
      }
    }
  };

  const satExamIsDone = (data) => {
    currGainChart.current.frequency = data.frequency;
    currGainChart.current.saturation_input = data.saturation.x;
    currGainChart.current.saturation_output = data.saturation.y;
  };

  const powerExamIsDone = (data) => {
    var tempData = nestedCopy(currGainData.current);
    if (data.saturation) {
      currGainChart.current.frequency = data.frequency;
      currGainChart.current.saturation_input = data.saturation.x;
      currGainChart.current.saturation_output = data.saturation.y;
    }
    currGainChart.current.compression_input = data.compression.x;
    currGainChart.current.compression_output = data.compression.y;
    currGainChart.current.results = tempData;
    if (chartIndex.current === -1) {
      chartIndex.current = 0;
      setPreviousGainData(nestedCopy(currGainData.current));
    }
    allPreviousGainTests.current.push({ ...currGainChart.current });
    currGainData.current = [];
    currGainChart.current = {};
    handleResetGainChart();
  };

  const setUpTest = (values) => {
    _initialFormValues.current = values;
    saturationTestParams.current = { ...values };
  };

  const updateSaturationData = (newData) => {
    setSatData((prevData) => [
      ...prevData,
      {
        index: newData.index,
        frequency: parseFloat(newData.frequency),
        saturationInput: parseFloat(newData.saturation_input),
        saturationOutput: parseFloat(newData.saturation_output),
        compressionInput: parseFloat(newData.compression_input),
        compressionOutput: parseFloat(newData.compression_output),
        specifiedOutput: parseFloat(newData.specified_output),
        gain: parseFloat(newData.gain),
      },
    ]);
    handleResetSaturationChart();
  };

  const processData = (newData) => {
    let processedArray = [];
    for (let i = 0; i < newData.length; i++) {
      let processedData = {
        index: newData[i].index,
        inputPower: parseFloat(newData[i].input_power),
        frequency: parseFloat(newData[i].frequency),
        outputPower: parseFloat(newData[i].output_power),
        readValue: parseFloat(newData[i].read_value),
        gain: parseFloat(newData[i].gain),
      };
      processedArray.push(processedData);
    }
    return processedArray;
  };

  const updateCurrentGainData = (newGainData) => {
    setCurrentGainData(nestedCopy(processData(newGainData)));
    currGainData.current = nestedCopy(processData(newGainData));
    handleResetGainChart();
  };

  const handleSocketMessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    if (dataFromServer.cable || dataFromServer.coupler) {
      setInProcess(false);
      setCable(dataFromServer.cable ? dataFromServer.cable : null);
      setCoupler(dataFromServer.coupler ? dataFromServer.coupler : null);
      missingCableResults.current = dataFromServer.cable
        ? dataFromServer.cable
        : null;
      missingCouplerResults.current = dataFromServer.coupler
        ? dataFromServer.coupler
        : null;
      setMissingCalibrations(true);
    }
    if (dataFromServer.new_frequency || !isNaN(dataFromServer.new_frequency)) {
      setCurrentFrequency(
        parseFloat(parseFloat(dataFromServer.new_frequency).toFixed(MAX_PRECISION))
      );
    } else if (dataFromServer.past_data) {
      handlePastData(dataFromServer.past_data, dataFromServer.unit_status);
      // setUpPowerExams(dataFromServer.past_data.power_exams);
      // setSatData(dataFromServer.past_data.saturation_data);
      // handleResetSaturationChart();
      // if (dataFromServer.unit_status) {
      //   setUnitStatus(
      //     dataFromServer.unit_status[dataFromServer.unit_status.length - 1]
      //   );
      //   setStatusHistory(dataFromServer.unit_status);
      // }

      // More handling for power exams.
    } else if (dataFromServer.new_saturation_data)
      updateSaturationData(dataFromServer.new_saturation_data);
    else if (dataFromServer.new_power_exam_data) {
      updateCurrentGainData(dataFromServer.new_power_exam_data);
      handleResetGainChart();
      updateTimer(changeMessage, dataFromServer.etc);
    } else if (dataFromServer.power_exam_done)
      powerExamIsDone(dataFromServer.power_exam_done);
    else if (dataFromServer.saturation_exam_done)
      satExamIsDone(dataFromServer.saturation_exam_done);
    else if (dataFromServer.saved) {
      fetchSaturationExams();
    } else if (dataFromServer.error) {
      changeMessage(dataFromServer.error, "#F08080");
    } else if (dataFromServer.unit_status) {
      setUnitStatus(dataFromServer.unit_status);
      updateStatusHistory(dataFromServer.unit_status);
    } else if (dataFromServer.status) {
      handleStatus(dataFromServer.status);
    } else if (dataFromServer.disconnect) {
      handleDisconnect(dataFromServer.disconnect);
    }
  };

  const handleDisconnect = (disconnect) => {
    if (disconnect.status) {
      setInProcess(false);
      // setDisconnect(true);
    }
    changeMessage(disconnect.message, "#F08080");
  };

  const handleStatus = (status) => {
    switch (status) {
      case "started":
        setStopExam(false);
        setLoading(false);
        setInProcess(true);
        break;
      case "stopped":
        setStopExam(true);
        setInProcess(false);
        break;
      case "closed":
        setClosed(true);
        break;
      case "done":
        setInProcess(false);
        setFormLayout(nestedCopy(_initialFormLayout.current));
        setCurrentGainData([]);
        handleResetGainChart();
        changeMessage("Saturation Exam Complete", "#A0D6B4");
        (async () => {
          let examAPIRouter = new ExamAPIRouter();
          await examAPIRouter.getExams("sat_exams").then((data) => {
            handleSaturationExams(data);
          });
        })();
        break;
      default:
    }
  };

  const updateStatusHistory = (status) => {
    setStatusHistory((prevData) => [
      ...prevData,
      {
        index: prevData.length,
        status: status.status,
        timestamp: status.timestamp,
      },
    ]);
  };

  const handlePastData = (pastData, unit_status = null) => {
    setUpPowerExams(pastData.power_exams);
    setSatData(formatSaturationResults(pastData.saturation_data));
    handleResetSaturationChart();
    if (unit_status) {
      setUnitStatus(unit_status[unit_status.length - 1]);
      setStatusHistory(unit_status);
    }
  };

  const handleSubmit = async (values) => {
    if (!satData.length) {
      setUpTest(values);
      setStopExam(false);
      setNewExam(false);
      if (!socket.current) {
        socket.current = setUpSocket(
          "saturation_exam",
          location.state ? location.state.exam_room : null,
          user ? user.first_name.toLowerCase() : location.state.user,
          setInProcess,
          handleSocketMessage
        );
      }
      const opened = await openSocket(socket.current);
      if (opened) {
        setLoading(true);
        sendSocketMessage(socket.current, {
          toggle: "start",
          message: {
            stand: values.stand.stand,
            cable: values.cable.id,
            coupler: values.coupler.id,
            amplifier: values.amplifier.id,
            power_head: values.powerHead.id,
            low_input: parseFloat(values.lowInput),
            high_input: parseFloat(values.highInput),
            fine_step: parseFloat(values.fineStep),
            coarse_step: parseFloat(values.coarseStep),
            duty_cycle: parseFloat(values.dutyCycle),
            coarse_tolerance: parseFloat(values.coarseTolerance / 100),
            fine_tolerance: parseFloat(values.fineTolerance / 100),
            low_freq: parseFloat(values.lowFrequency),
            high_freq: parseFloat(values.highFrequency),
            freq_step: parseFloat(values.freqStep),
            freq_list: values.frequencyList.replace(/\s/g, '').split(','),
            toggle_list: values.toggleList,
            peeks: parseFloat(values.peeks),
            input_offset: parseFloat(values.inputOffset),
            output_offset: parseFloat(values.outputOffset),
            toggle_p1db: values.toggleP1db,
            query_delay: parseFloat(values.queryDelay),
            query_unit: values.queryUnit,
            read_delay: values.readDelay,
            manual: values.manual,
          },
        });
      } else {
        return;
      }
    }
  };

  const continueExam = () => {
    setOpenSelection(true);
  };

  const selectParameters = (parameters) => {
    handleContinue(socket.current, {
      message: {
        power_head: parameters.serial,
        duty_cycle: parameters.dutyCycle,
        input_offset: parameters.inputOffset,
        output_offset: parameters.outputOffset,
      },
    });
  };
  const queryItems = async () => {
    let catalogAPIRouter = new CatalogAPIRouter();
    let formLayoutCopy = nestedCopy(_initialFormLayout.current);
    await catalogAPIRouter.getCatalogItems("stands").then((data) => {
      formLayoutCopy[0].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems("amplifiers").then((data) => {
      formLayoutCopy[1].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems("cables").then((data) => {
      formLayoutCopy[2].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems("couplers").then((data) => {
      formLayoutCopy[3].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems("power_heads").then((data) => {
      formLayoutCopy[4].menuItems = nestedCopy(data);
    });
    setFormLayout(nestedCopy(formLayoutCopy));
  };

  const handleNewExam = (state) => {
    if (state) queryItems();
    clearMessage();
    setNewExam(true);
  };

  const heading = () => (
    <Grid container justifyContent="center" alignContent="center">
      <Paper elevation={3}>
        {examMetaData && examMetaData.exam_params ? (
          <>
            <Grid>
              <Grid>
                <Typography variant="subtitle1">
                  Date Done: {new Date(examMetaData.date_done).toLocaleString()}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1">
                  Frequency Range:{" "}
                  {
                    examMetaData.exam_params.frequency_parameter.frequency_range
                      .low_frequency
                  }
                  -
                  {
                    examMetaData.exam_params.frequency_parameter.frequency_range
                      .high_frequency
                  }
                  GHz Frequency Step:{" "}
                  {examMetaData.exam_params.frequency_parameter.frequency_step}
                  GHz
                </Typography>
              </Grid>
              <Typography variant="subtitle1">
                Power Range:{" "}
                {examMetaData.exam_params.power_parameter.power_range.low_power}
                -
                {
                  examMetaData.exam_params.power_parameter.power_range
                    .high_power
                }
                dBm Input Offset:{" "}
                {examMetaData.exam_params.power_parameter.input_padding}
                dBm Output Offset:{" "}
                {examMetaData.exam_params.power_parameter.output_padding}
                dBm
              </Typography>
            </Grid>
          </>
        ) : null}
      </Paper>
    </Grid>
  );

  const left = () => (
    <IconButton
      onClick={() => {
        changeView(-1);
      }}
      size="small"
      disabled={!chartIndex.current ? true : false}
    >
      <ArrowBackIos />
    </IconButton>
  );

  const right = () => (
    <IconButton
      onClick={() => {
        changeView(1);
      }}
      size="small"
      disabled={
        chartIndex.current === allPreviousGainTests.current.length - 1
          ? true
          : false
      }
    >
      <ArrowForwardIos />
    </IconButton>
  );

  const previousPowerExam = () => {
    let lines = { ...PowExamLines, dots: [] };
    let compressionPoint = {
      ...compPointDot,
      x: allPreviousGainTests.current[chartIndex.current].compression_input,
      y: allPreviousGainTests.current[chartIndex.current].compression_output,
    };

    let saturationPoint = {
      ...satPointDot,
      x: allPreviousGainTests.current[chartIndex.current].saturation_input,
      y: allPreviousGainTests.current[chartIndex.current].saturation_output,
    };
    lines.dots.push(compressionPoint);
    lines.dots.push(saturationPoint);
    return (
      <>
        <Chart
          filename={
            "power_exam" +
            parseFloat(
              allPreviousGainTests.current[chartIndex.current].frequency
            ).toFixed(MAX_PRECISION)
          }
          chartLabel={`Power exam: ${parseFloat(
            allPreviousGainTests.current[chartIndex.current].frequency
          ).toFixed(MAX_PRECISION)}
      GHz`}
          headers={powerExamHeaders}
          lines={lines}
          showCheckBoxes={false}
          // columns={compressionColumns}
          checkBoxes={nestedCopy(compressionCheckboxes)}
          chartData={previousGainData}
          resetChart={resetGainChart}
          handleResetChart={handleResetGainChart}
        />
      </>
    );
  };

  const currentPowerExam = () => {
    let lines = { ...PowExamLines, dots: [] };
    let compressionPoint = {
      ...compPointDot,
      x: currGainChart.current.compression_input,
      y: currGainChart.current.compression_output,
    };
    let saturationPoint = {
      ...satPointDot,
      x: currGainChart.current.saturation_input,
      y: currGainChart.current.saturation_output,
    };
    lines.dots.push(compressionPoint);
    lines.dots.push(saturationPoint);
    return (
      <>
        {currentGainData.length ? (
          <Grid item xs>
            <Chart
              chartLabel={`Current Power Test 
            ${parseFloat(currentFrequency).toFixed(MAX_PRECISION)} GHz`}
              lines={{ ...lines }}
              unit="dBm"
              checkBoxes={nestedCopy(compressionCheckboxes)}
              showCheckBoxes={false}
              headers={powerExamHeaders}
              chartData={currentGainData}
              resetChart={resetGainChart}
              inProcess={inProcess}
              handleResetChart={handleResetGainChart}
            />
          </Grid>
        ) : null}
      </>
    );
  };

  const amplifierInfo = () => (
    <>
      {amplifier ? (
        <Typography variant="h6">
          Name : {amplifier.name} Model : {amplifier.model} Serial :{" "}
          {amplifier.serial} Specified Power : {amplifier.spec_power}
        </Typography>
      ) : null}
      {unitStatus ? (
        <>
          <Controls.Fab
            text={viewUnitStatus ? "Hide Status" : "View Unit Status"}
            onClick={handleViewUnitStatus}
          />
          {viewUnitStatus ? (
            <AmplifierStatus
              unitStatus={unitStatus}
              statusHistory={statusHistory}
            />
          ) : null}
        </>
      ) : null}
    </>
  );

  useEffect(() => {
    if (location.state && location.state.stand) {
      (async () => {
        socket.current = await joinExam(
          socket.current,
          "saturation_exam",
          location.state.stand.exam_room,
          location.state.stand.exam_id,
          location.state.stand.user,
          setInProcess,
          handleSocketMessage
        );
      })();
    } else {
      if (location.state && location.state.item) {
        handleChartChange(location.state.item);
      }
    }

    (async () => {
      let examAPIRouter = new ExamAPIRouter();
      await examAPIRouter.getExams("sat_exams").then((data) => {
        handleSaturationExams(data);
      });
    })();

    return () => {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);
  if (loading)
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  return !satExams ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {heading()}
      <MissingCalibrations
        cable={cable}
        coupler={coupler}
        parameters={saturationTestParams.current}
        setCable={setCable}
        setCoupler={setCoupler}
        test={true}
      />
      {standButton(location, socket)}
      {amplifierInfo()}
      <PowerHeadSelection
        openPopup={openSelection}
        setOpenPopup={setOpenSelection}
        handleSubmit={selectParameters}
      />
      {inProcess ||
        satData.length ||
        currentGainData.length ||
        previousGainData.length ||
        stopExam ? (
        <>
          <Controls.ChartButtons
            inProcess={inProcess}
            clear={clear}
            data={satData}
          />
          <Controls.ExamControls
            inProcess={inProcess}
            handleStop={() => handleStop(socket.current)}
            handleContinue={continueExam}
            stopped={stopExam}
          />
          <Grid container justifyContent={"center"}>
            <Chart
              filename={"sat_exam_chart"}
              chartLabel={"Saturation Test"}
              lines={{ ...SatExamLines }}
              headers={satExamHeaders}
              chartData={satData}
              inProcess={inProcess}
              resetChart={resetSatChart}
              handleResetChart={handleResetSaturationChart}
              unit="GHz"
              checkBoxes={nestedCopy(saturationExamCheckboxes)}
              // showCheckBoxes={true}
              showCheckBoxes={false}
            />
          </Grid>
          <Divider />
          <Grid container justifyContent={"center"}>
            {allPreviousGainTests.current.length ? (
              <>
                <Grid item xs>
                  {left()}
                  {right()}
                  {previousPowerExam()}
                </Grid>
              </>
            ) : null}
            {currentPowerExam()}
          </Grid>
        </>
      ) : (
        <Table
          headCells={saturationExamHeadCells}
          tableItems={satExams}
          initialValues={{
            ..._initialFormValues.current,
          }}
          formLayout={formLayout}
          setFormLayout={setFormLayout}
          changeFormLayout={changeFormLayout}
          name={"Saturation Exams"}
          searchByValue={"amplifier_serial"}
          openPopup={newExam}
          formProcessing={handleNewExam}
          handleSubmit={handleSubmit}
          tableCount={totalSatExams}
          handleDelete={handleDelete}
          handleChartChange={handleChartChange}
          tableButtons={Controls.TableExamResultsButtons}
          formButtons={Controls.ExamSubmitButtons}
        />
      )}
      <TestPopup
        title={"Uncalibrated Instruments"}
        openPopup={missingCalibrations}
        setOpenPopup={setMissingCalibrations}
      >
        <MissingTestForm
          setOpenPopup={setMissingCalibrations}
          missingCableResults={missingCableResults.current}
          missingCouplerResults={missingCouplerResults.current}
          cable={
            saturationTestParams.current
              ? saturationTestParams.current.cable.id
              : null
          }
          coupler={
            saturationTestParams.current
              ? saturationTestParams.current.coupler.id
              : null
          }
        />
      </TestPopup>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(SaturationTest);
