import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { convertTime } from "./Constants";
import { CalibrationAPIRouter } from "../Routers/CalibrationAPIRouter";
import ExamAPIRouter from "../Routers/ExamAPIRouter";
import { useNavigate } from "react-router-dom/";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function StandCard(props) {
  const { stand } = props;
  const [item, setItem] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const displayExam = () => {
    let exam = stand.exam_type.toLowerCase();
    if (exam.includes("calibration")) {
      return navigate(
        `/calibration/${exam.split(" ")[0]}/${
          item.coupler ? item.coupler.serial : item.cable.serial
        }`,
        {
          state: {
            item: item.coupler || item.cable,
            active: item.is_active,
            stand: stand,
            name: stand.exam_type.split(" ")[0],
          },
        }
      );
    } else {
      return navigate(`/test/${exam.split(" ")[0]}/${item.id}`, {
        state: {
          item: item,
          active: item.is_active,
          stand: stand,
          name: stand.exam_type.split(" ")[0],
        },
      });
    }
  };

  const getExamInfo = async (type, id) => {
    if (type) {
      if (type.toLowerCase().includes("calibration")) {
        let calibrationAPIRouter = new CalibrationAPIRouter();
        await calibrationAPIRouter
          .getSingleCalibration(id)
          .then((data) => setItem(data));
      } else if (type.toLowerCase().includes("saturation")) {
        let examAPIRouter = new ExamAPIRouter();
        await examAPIRouter
          .getExamById("sat_exams", id)
          .then((data) => setItem(data));
      } else if (type.toLowerCase().includes("burn")) {
        let examAPIRouter = new ExamAPIRouter();
        await examAPIRouter
          .getExamById("burn_ins", id)
          .then((data) => setItem(data));
      }
    }
  };

  React.useEffect(() => {
    getExamInfo(stand.exam_type, stand.exam_id);
    const intervalCall = setInterval(() => {
      getExamInfo(stand.exam_type, stand.exam_id);
    }, 10000);
    return () => {
      clearInterval(intervalCall);
    };
  }, [stand.exam_type, stand.exam_id]);

  if (!stand || !item) return null;
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        title={`Stand ${stand.serial}`}
        subheader={`${stand.exam_type} Started: 
          ${
            item.date_started
              ? new Date(item.date_started).toLocaleString()
              : "N/A"
          }`}
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/units/{item.unit}.jpg"
        alt="unit"
      /> */}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Status :{" "}
          {item.is_active ? "In Progress" : item.etc ? "Stopped" : "Done!"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {"Estimated completion time: " + convertTime(item.etc)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Current user: {stand.user || "None"}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <IconButton onClick={displayExam} aria-label="display exam">
          <SmartDisplayIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph></Typography>
          <Typography paragraph></Typography>
          <Typography paragraph></Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
