/**
 *  Styling for range sliders.
 */
import React from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

export const Container = styled("div")(({ theme }) => ({
  width: 300,
  padding: 30,
}));

function valueText(value, unit) {
  return `${value}${unit}`;
}

export default function RangeSlider(props) {
  const { name, unit, value, min, max, step, marks, onChange } = props;
  return (
    <Container>
      <Typography id="range-slider" gutterBottom>
        {name}
      </Typography>
      <Slider
        value={value}
        min={min}
        marks={marks}
        step={step}
        max={max}
        onChange={onChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={() => {
          valueText(value, unit);
        }}
      />
    </Container>
  );
}
