/**
 *  Buttons for the calibration results table.
 */
import React, { useState } from "react";
import Controls from "./Controls";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import Form from "../Form/Form";
import { nestedCopy } from "./Constants";
import { InstrumentAPIRouter } from "../Routers/InstrumentAPIRouter";

export default function InstrumentTableButtons(props) {
  const {
    item,
    initialValues,
    editInitialValues,
    handleAdd,
    handleEdit,
    handleDelete,
    name,
    formLayout,
    editFormLayout,
  } = props;
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [valuesCopy, setValuesCopy] = useState({ ...item });
  const [editLayout, setEditLayout] = useState(
    editFormLayout ? nestedCopy(editFormLayout) : nestedCopy(formLayout)
  );
  let newLayout = null;
  const queryStands = async () => {
    let instrumentAPIRouter = new InstrumentAPIRouter();
    await instrumentAPIRouter.getEditStands().then((results) => {
      newLayout = handleDetail(results);
      // handleDetail(results);
    });
  };

  const handleDetail = (response, paging) => {
    if (response) {
      let formLayoutCopy = nestedCopy(editLayout);
      formLayoutCopy[1].menuItems = nestedCopy(response.generators);
      formLayoutCopy[2].menuItems = nestedCopy(response.meters);
      return formLayoutCopy;
      // setEditLayout(nestedCopy(formLayoutCopy));
    }
  };

  const parseProperty = (property) => {
    let splitProperty = property.split("_");
    let key = splitProperty[0];
    for (let i = 1; i < splitProperty.length; i++) {
      key +=
        splitProperty[i].charAt(0).toUpperCase() + splitProperty[i].slice(1);
    }
    return key;
  };

  const editHelper = () => {
    let formValuesCopy = editInitialValues
      ? { ...editInitialValues }
      : { ...initialValues };
    let formLayoutCopy = newLayout
      ? nestedCopy(newLayout)
      : nestedCopy(editLayout);
    for (const key in item) {
      for (const form of formLayoutCopy) {
        if (item[key] instanceof Object) {
          if (form.menuItemName === key && !form.disabled) {
            if (!key) {
              form.defaultItem = item[key];
              break;
            }
            if (form.menuItems.length) {
              let menuItemExists = false;
              for (const menuItem of form.menuItems) {
                if (item[key].serial === menuItem.serial) {
                  menuItemExists = true;
                }
              }
              if (!menuItemExists) form.menuItems.push({ ...item[key] });
            } else form.menuItems.push({ ...item[key] });
            form.defaultItem = { ...item[key] };
            break;
          }
        } else {
          if (form.menuItemName === key) {
            form.menuItems.push({ ...item[key] });
            form.defaultItem = item[key];
          }
        }
        if (key.includes("_")) {
          let parsedKey = parseProperty(key);
          if (item[key] instanceof Object) {
            for (const subProperty in item[key]) {
              let subKey = parseProperty(subProperty);
              if (subKey !== "id")
                formValuesCopy[subKey] = item[key][subProperty];
            }
          } else {
            formValuesCopy[parsedKey] = item[key];
          }
        } else formValuesCopy[key] = item[key];
      }
    }
    setValuesCopy({ ...formValuesCopy });
    setEditLayout(nestedCopy(formLayoutCopy));
    setOpenEditPopup(true);
  };
  const editItem = () => {
    if (name === "stand")
      queryStands().then(() => {
        editHelper(newLayout);
      });
    else editHelper();
  };

  return (
    <>
      <IconButton
        color="primary"
        disabled={true}
        variant="contained"
        onClick={() => {
          handleAdd();
        }}
      >
        <AddIcon fontSize="small" />
      </IconButton>
      <Controls.ActionButton color="success" onClick={editItem}>
        <EditIcon fontSize="small" />
      </Controls.ActionButton>
      <IconButton color="error" disabled={true} onClick={() => handleDelete()}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Controls.Popup
        title={`Editing a ${name}`}
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
      >
        <Form
          initialValues={valuesCopy}
          formLayout={editLayout}
          handleSubmit={handleEdit}
          openPopup={openEditPopup}
          setOpenPopup={setOpenEditPopup}
          buttons={Controls.InstrumentSubmitButtons}
        />
      </Controls.Popup>
    </>
  );
}
