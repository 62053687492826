/**
 * Author: Draven Pena
 * File: lookup/components.js
 * Project: Rep dollar
 * Date: 9/8/2020
 *
 * Notes:
 *  These are component functions that serve a small function in this app.
 */

/**
 * @function getCookie
 * @param {string} name name of the cookie being looked for
 */
function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

/**
 * @function backendLookup
 * @param {string} method REST api method
 * @param {string} endpoint viewset function being called
 * @param {dict} data POST or PUT data that viewsets will receive
 * @description Handle django api post and get requests from the React Components
 */
export function backendLookup(method, endpoint, data) {
  // Turn POST information into a json object
  let jsonData;
  if (data) {
    jsonData = JSON.stringify(data);
  }
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest(); // Local
    const url = `${process.env.REACT_APP_API_URL}/api/${endpoint}`;
    xhr.responseType = "json";
    const csrftoken = getCookie("csrftoken");
    xhr.open(method, url);
    xhr.setRequestHeader("Content-Type", "application/json");

    if (csrftoken) {
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.setRequestHeader(
        "Authorization",
        `JWT ${localStorage.getItem("access")}`
      );
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    }
    xhr.onload = function() {
      if (xhr.status === 403) {
        const detail = xhr.response.detail;
        if (detail === "Authentication credentials were not provided.") {
          window.location.href = "/login?showLoginRequired=true";
        }
      }
      resolve({ answer: xhr.response, status: xhr.status });
    };
    xhr.onerror = function(e) {
      reject({
        answer: "There request was an error in lookup",
        status: xhr.status,
      });
    };
    xhr.send(jsonData);
  });
}

/**
 * @function handleResponse
 * @abstract return the response from the api or true if item is created.
 *
 * @param {Object} response json response from the api
 */
export function handleResponse(response) {
  if (response.status === 205) {
    return true;
  } else if (response.status === 200 || response.status === 201) {
    return response.answer;
  } else if (response.status === 404) {
    return null;
  } else if (response.status >= 400) {
    throw new Error("Big Error", response.error);
  }
}
