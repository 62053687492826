/**
 * Buttons for catalog edit forms.
 */
import React from "react";
import Controls from "./Controls";
import Grid from "@mui/material/Grid";

export default function CatalogEditButtons(props) {
  const { handleSubmit, values } = props;
  return (
    <Grid container justifyContent="center">
      <Controls.ActionButton
        color="primary"
        variant="contained"
        onClick={() => handleSubmit(values)}
      >
        Save
      </Controls.ActionButton>
    </Grid>
  );
}
