import React from "react";
import TimelineIcon from "@mui/icons-material/Timeline";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import MemoryIcon from "@mui/icons-material/Memory";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  addCompTextFields,
  burnInHeadCells,
  durationTextFields,
  formatPowerResults,
  formatSaturationResults,
  saturationColumns,
  saturationExamTextFields,
} from "../Test/ExamConstants";
import {
  BurnInLines,
  CalibrationLines,
  PowExamLines,
  SatExamLines,
  burnInCheckboxes,
  cableCalibrationCheckboxes,
  compressionCheckboxes,
  couplerCalibrationCheckboxes,
  saturationExamCheckboxes,
} from "../Chart/ChartConstants";
import { formatCalResults } from "../Calibration/CalConstants";
import {
  getCalibrationResults,
  getDatedCalibrations,
} from "../Routers/CalibrationAPIRouter";
import { getExamResults, getDatedExams } from "../Routers/ExamAPIRouter";
import { getCatalogItems } from "../Routers/CatalogAPIRouter";
import {
  amplifierHeadCells,
  cableHeadCells,
  couplerHeadCells,
} from "../Catalog/CataConstants";

export const cableCalFECells = [
  { id: "serial", label: "Cable" },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "frequency_step", label: "Frequency Step" },
  { id: "date_started", label: "Calibration Date" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];
export const couplerCalFECells = [
  { id: "serial", label: "Coupler" },
  { id: "cable", label: "Cable" },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "frequency_step", label: "Frequency Step" },
  { id: "date_started", label: "Calibration Date" },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const examSteps = [
  // { label: "Initialization", component: InitialStep },
  {
    label: "Cable Calibration",
    query: getCatalogItems,
    queryParameters: { endpoint: "cables", additional: { cable: "" } },
    getResults: { cal: getDatedCalibrations },
    formatResults: { cal: formatCalResults },
    tableHeadCells: cableHeadCells,
    charts: [{ label: "Cable Calibration", type: "cal" }],
    lines: { cal: CalibrationLines },
    columns: { cal: null },
    // columns: { cal: pastCalibrationResultsColumns },
    checkBoxes: {
      cal: { checkBoxes: cableCalibrationCheckboxes, showCheckBoxes: false },
    },
  },
  {
    label: "Coupler Calibration",
    query: getCatalogItems,
    queryParameters: {
      endpoint: "couplers",
      additional: { cable: "", coupler: "" },
    },
    getResults: { cal: getDatedCalibrations },
    formatResults: { cal: formatCalResults },
    tableHeadCells: couplerHeadCells,
    charts: [{ label: "Coupler Calibration", type: "cal" }],
    lines: { cal: CalibrationLines },
    columns: { cal: null },
    // columns: { cal: pastCalibrationResultsColumns },
    checkBoxes: {
      cal: { checkBoxes: couplerCalibrationCheckboxes, showCheckBoxes: false },
    },
  },
  {
    label: "Saturation Test",
    tableTitle: "Amplifiers",
    query: getCatalogItems,
    queryParameters: {
      endpoint: "amplifiers",
      additional: ["cable", "coupler"],
    },
    getResults: { powerExam: getExamResults, satExam: getDatedExams },
    formatResults: {
      powerExam: formatPowerResults,
      satExam: formatSaturationResults,
    },
    tableHeadCells: amplifierHeadCells,
    charts: [
      { label: "Previous", type: "powerExam" },
      { label: "Current", type: "powerExam" },
      { label: "Saturation", type: "satExam" },
    ],
    lines: { powerExam: PowExamLines, satExam: SatExamLines },
    columns: { satExam: saturationColumns },
    checkBoxes: {
      powerExam: { checkBoxes: compressionCheckboxes, showCheckBoxes: false },
      satExam: { checkBoxes: saturationExamCheckboxes, showCheckBoxes: true },
    },
  },
  {
    label: "Burn In Test",
    tableTitle: "Amplifiers",
    query: getCatalogItems,
    queryParameters: {
      endpoint: "amplifiers",
      additional: ["cable", "coupler"],
    },
    getResults: { burnIn: getCalibrationResults },
    formatResults: { burnIn: formatCalResults },
    tableHeadCells: burnInHeadCells,
    charts: [{ label: "Burn In", type: "burnIn" }],
    lines: { burnIn: BurnInLines },
    columns: { burnIn: null },
    checkBoxes: {
      burnIn: { checkBoxes: burnInCheckboxes, showCheckBoxes: true },
    },
  },
];

export const examIcons = {
  1: <SettingsIcon />,
  2: <SettingsInputComponentIcon />,
  3: <MemoryIcon />,
  4: <TimelineIcon />,
  5: <FireplaceIcon />,
};

export const fullExamForm = {
  initialValues: {
    stand: "",
    amplifier: "",
    coupler: "",
    cable: "",
    powerHead: "",
    lowInput: "",
    highInput: "",
    fineStep: 0.1,
    lowFrequency: "",
    highFrequency: "",
    coarseStep: 1,
    freqStep: "",
    dutyCycle: "",
    coarseTolerance: 10,
    fineTolerance: 1,
    peeks: 3,
    inputOffset: 0,
    outputOffset: 0,
    queryDelay: 60,
    days: 0,
    hours: 0,
    minutes: 10,
    seconds: 0,
    meterDelay: 1,
    toggleP1db: false,
    queryUnit: false,
  },
  formLayout: [
    {
      type: "select",
      menuItems: [],
      menuItemName: "stand",
      defaultItem: null,
      menuItemTextValue: "stand",
      label: "Stand",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "amplifier",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Amplifier",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "cable",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Cable",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "coupler",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Coupler",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "powerHead",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Power Head",
    },
    {
      type: "textfields",
      fields: saturationExamTextFields,
      fieldsName: "CompressionText",
    },
    {
      type: "additional",
      title: "Full Exam Parameters",
      fields: [
        {
          type: "textfields",
          fields: addCompTextFields,
          fieldsName: "Compression",
        },
        {
          type: "textfields",
          fields: durationTextFields,
          fieldsName: "Duration",
        },
      ],
    },
    {
      type: "slider",
      title: "Input Offset",
      name: "inputOffset",
      value: 0,
    },
    {
      type: "slider",
      title: "Output Offset",
      name: "outputOffset",
      value: 0,
    },
    {
      type: "toggle",
      label: "Toggle P1dB Exams",
      name: "toggleP1db",
    },
    {
      type: "toggle",
      label: "Query Unit",
      name: "queryUnit",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};
