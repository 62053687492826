/**
 *  Style implementation for dialog material ui compenent.
 */
import MuiDialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  padding: theme.spacing.apply(2),
  position: "absolute",
  top: theme.spacing(5),
  color: "black",
}));
