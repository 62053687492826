/**
 * Page listing all power heads. Allows CRUD functions for power heads.
 */
import React, { PureComponent } from "react";
import { newPowerHeadForm, powerHeadCells } from "../Catalog/CataConstants";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Table from "../Table/Table";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Controls from "../controls/Controls";
import { nestedCopy } from "../controls/Constants";

export default class PowerHeads extends PureComponent {
  _isMounted = false;
  currentAPIPage = 1;
  constructor(props) {
    super(props);
    this.state = {
      powerHeads: null,
      totalPowerHeads: 0,
      nextPage: false,
      loading: false,
    };
    this.catalogApiRouter = this.props.apiRouter || new CatalogAPIRouter();
  }

  handleDetail = (response, paging) => {
    if (!response || response.length === 0)
      this.setState({
        powerHeads: [],
        loading: false,
      });
    else {
      if (paging) {
        this.setState({
          totalPowerHeads: response.count,
          nextPage: response.next ? true : false,
          powerHeads: this.state.powerHeads.concat(response.results),
          loading: false,
        });
      } else {
        this.setState({
          totalPowerHeads: response.count,
          nextPage: response.next ? true : false,
          powerHeads: response.results,
          loading: false,
        });
      }
    }
  };

  handleNextPage = async () => {
    if (this.state.nextPage) {
      await this.catalogApiRouter
        .getPowerHeads(++this.currentAPIPage)
        .then((response) => {
          this.setState({
            powerHeads: this.state.powerHeads.concat(response.results),
            nextPage: response.next ? true : false,
          });
        });
    }
  };

  handler = async () => {
    for (let i = 1; i < this.currentAPIPage + 1; i++) {
      await this.catalogApiRouter.getPowerHeads(i).then((response) => {
        this.handleDetail(
          response,
          i !== 1 && i < this.currentAPIPage + 1 ? true : false
        );
      });
    }
  };

  handleEdit = async (values) => {
    const newPowerHead = {
      serial: values.serial,
      name: values.name,
      pulsing: values.pulsing,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      power_range: {
        low_power: parseFloat(values.lowPower),
        high_power: parseFloat(values.highPower),
      },
      cal_factor: nestedCopy(values.calFactor),
    };
    return await this.catalogApiRouter
      .updatePowerHead(values.id, newPowerHead)
      .then(this.handler);
  };

  handleSubmit = async (values) => {
    const newPowerHead = {
      serial: values.serial,
      name: values.name,
      pulsing: values.pulsing,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      power_range: {
        low_power: parseFloat(values.lowPower),
        high_power: parseFloat(values.highPower),
      },
      cal_factor: nestedCopy(values.calFactor),
    };
    await this.catalogApiRouter.postPowerHead(newPowerHead).then(this.handler);
  };

  handleDelete = async (id) => {
    await this.catalogApiRouter.deletePowerHead(id).then(this.handler);
  };

  componentDidMount() {
    this._isMounted = true;
    this.catalogApiRouter.getPowerHeads().then((results) => {
      this.setState({ loading: true });
      if (this._isMounted) this.handleDetail(results);
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.loading) this.setState({ loading: false });
  }

  render() {
    /* These arrays are props to be passed on to Table component for rendering */
    const names = ["name", "serial", "freq_range", "power_range"];
    const textFields = [
      "Manufacturer",
      "Serial Number",
      "Frequency Range",
      "Power Range",
    ];

    return !this.state.powerHeads ? (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    ) : (
      <Table
        headCells={powerHeadCells}
        tableItems={this.state.powerHeads}
        tableButtons={Controls.TableCatalogButtons}
        initialValues={newPowerHeadForm.initialValues}
        formLayout={newPowerHeadForm.formLayout}
        name="Power Head"
        handleSubmit={this.handleSubmit}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        handleNextPage={this.handleNextPage}
        nextPage={this.state.nextPage}
        tableCount={this.state.totalPowerHeads}
        disabled={false}
        additional={true}
        names={names}
        searchByValue={"name"}
        textFields={textFields}
      />
    );
  }
}
