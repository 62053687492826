/**
 * Gauge styled component for amplifier status.
 */
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";
import { Tooltip } from "@mui/material";

export default function Gauge(props) {
  const { size, value, strValue, low, high, name, fullName } = props;
  const dcpy = (o) => {
    return JSON.parse(JSON.stringify(o));
  };
  let kStyle = {
    width: size,
    height: size,
  };
  let degree = value / (high + Math.abs(low));
  if (degree < 0) degree = 0;
  else if (degree > 0.5) degree = 0.5;
  let fillStyle = dcpy(kStyle);
  if (isNaN(degree)) {
    degree = 0.5;
    fillStyle["backgroundColor"] = "grey";
  } else
    fillStyle["backgroundColor"] =
      degree > 0.45
        ? "#ea342f"
        : degree > 0.35
        ? "#e5b32f"
        : degree > 0.2
        ? "#51d23b"
        : "#44bbd8";
  fillStyle.transform = `rotate(${degree}turn)`;
  return (
    <>
      <Grid container direction="column" justifyContent="center">
        <Tooltip title={fullName} arrow>
          <Typography variant="h6">{name}</Typography>
        </Tooltip>
        <Grid container justifyContent="center">
          <div className="gauge">
            <div className="gauge__body">
              <div className="gauge__fill" style={fillStyle} />
              <div className="gauge__cover">{strValue}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
