/**
 *  Buttons for instrument forms.
 */
import React from "react";
import Grid from "@mui/material/Grid";
import Controls from "./Controls";

export default function InstrumentSubmitButtons(props) {
  const { values, validate, handleSubmit, disableSubmit, setOpenPopup } = props;
  return (
    <Grid container justifyContent="center">
      <Controls.Button
        text={"Submit"}
        color="primary"
        variant="contained"
        disabled={disableSubmit}
        onClick={() => {
          if (validate(values)) {
            handleSubmit(values);
            setOpenPopup(false);
          }
        }}
      />
    </Grid>
  );
}
