/**
 * Handles table cells for exams.
 */
import { TableCell } from "@mui/material";
import React from "react";

export default function ExamCells(props) {
  const { cells, item } = props;
  if (cells.id === "amplifier")
    return <TableCell>{item.amplifier_ut.name}</TableCell>;
  else if (cells.id === "driveCable")
    return <TableCell>{item.drive_cable.serial}</TableCell>;
  else if (cells.id === "offsetCoupler")
    return <TableCell>{item.offset_coupler.serial}</TableCell>;
  else if (cells.id === "stand")
    return <TableCell>{item.sat_exam_params.stand.stand}</TableCell>;
  else if (cells.id === "powerRange")
    return (
      <TableCell>
        {`${item.sat_exam_params.power_parameter.power_range.low_power} -
          ${item.sat_exam_params.power_parameter.power_range.high_power} dBm`}
      </TableCell>
    );
  else if (cells.id === "freqRange") {
    if (item.sat_exam_params.frequency_parameter.freq_range)
      return (
        <TableCell>
          {`${item.sat_exam_params.frequency_parameter.freq_range.low_freq} -
            ${item.sat_exam_params.frequency_parameter.freq_range.high_freq} GHz`}
        </TableCell>
      );
    return (
      <TableCell>
        {`${item.sat_exam_params.frequency_parameter.test_freq}GHz`}
      </TableCell>
    );
  } else if (cells.id === "freqStep")
    return (
      <TableCell>
        {item.sat_exam_params.frequency_parameter.freq_step}
      </TableCell>
    );
  else if (cells.id === "dutyCycle")
    return <TableCell>{item.sat_exam_params.duty_cycle}</TableCell>;
  else if (cells.id === "growthTolerance")
    return <TableCell>{item.sat_exam_params.growth_tolerance}</TableCell>;
  else return null;
}
