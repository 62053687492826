/**
 *  Style implementation for fab material ui compenent.
 */
import React from "react";
import MuiFab from "@mui/material/Fab";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

export const GreenFab = styled(MuiFab)(({ theme }) => ({
  position: "absolute",
  color: theme.palette.common.white,
  backgroundColor: green[500],
  "&:hover": {
    backgroundColor: green[600],
  },
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  zIndex: 100,
}));

export default function Fab(props) {
  const { text, onClick, size, variant, color } = props;
  return (
    <GreenFab
      size={size || "medium"}
      variant={variant || "extended"}
      onClick={onClick}
      color={color || "primary"}
    >
      {text}
    </GreenFab>
  );
}
