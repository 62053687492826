/**
 * Page listing all exams handling redirects to exams.
 */
import React, { useState } from "react";
import BurnInTest from "./BurnInTest";
import SaturationTest from "./SaturationTest";
import Divider from "@mui/material/Divider";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import Controls from "../controls/Controls";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useLocation, useNavigate } from "react-router-dom";
export default function Tests(props) {
  const { changeMessage, clearMessage, fullExam } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [testType, setTestType] = useState(
    location.state && location.state.name
      ? location.state.name.split(" ")[0].toLowerCase()
      : "saturation"
  );

  if (fullExam && testType !== fullExam) setTestType(fullExam);
  const buttons = [
    { text: "saturation", icon: <TimelineIcon fontSize="large" /> },
    { text: "burn", icon: <FireplaceIcon fontSize="large" /> },
  ];
  const handleTypeChange = (event, newType) => {
    if (newType && testType !== newType) {
      setTestType(newType);
      return navigate(`/test/${newType}`);
    }
  };

  return (
    <div>
      {fullExam ? null : (
        <Controls.ToggleButtons
          value={testType}
          onChange={handleTypeChange}
          buttons={buttons}
        />
      )}

      {testType === "" || fullExam ? null : <Divider />}
      {testType === "saturation" ? (
        <SaturationTest
          item={location.state ? location.state.item : null}
          changeMessage={changeMessage}
          clearMessage={clearMessage}
          {...props}
        />
      ) : testType === "burn" ? (
        <BurnInTest
          item={location.state ? location.state.item : null}
          changeMessage={changeMessage}
          clearMessage={clearMessage}
          {...props}
        />
      ) : null}
    </div>
  );
}
