import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress, IconButton, Paper, Typography } from "@mui/material";
import Table from "../Table/Table";
import Controls from "../controls/Controls";
import ExamAPIRouter from "../Routers/ExamAPIRouter";
import {
  saturationExamCheckboxes,
  SatExamLines,
  satPointDot,
  compressionCheckboxes,
  PowExamLines,
  compPointDot,
} from "../Chart/ChartConstants";
import {
  powerExamHeaders,
  satExamHeaders,
  saturationColumns,
  saturationExamHeadCells,
} from "../Test/ExamConstants";
import { useLocation } from "react-router-dom";
import { MAX_PRECISION, nestedCopy } from "../controls/Constants";
import Chart from "../Chart/Chart";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export default function AmplifierDetails(props) {
  const location = useLocation();
  let allPreviousGainTests = useRef([]);
  let chartIndex = useRef(0);
  let item = useRef(location.state.item ? location.state.item : null);
  let name = useRef(location.state.name ? location.state.name : "Amplifier");
  const [exams, setExams] = useState(null);
  const [totalExams, setTotalExams] = useState(0);
  const [checkBoxes, setCheckBoxes] = useState(saturationExamCheckboxes);
  const [prevPowerData, setPrevPowerData] = useState([]);
  const [satData, setSatData] = useState([]);
  const [resetGainChart, setResetGainChart] = useState(false);
  const [resetSatChart, setResetSatChart] = useState(false);

  const handleExams = (response) => {
    if (!response) {
      setExams([]);
      setTotalExams(0);
    } else {
      setExams(response.results);
      setTotalExams(response.results.length);
    }
  };
  const queryExams = async () => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.getAmplifierExams(item.id).then((response) => {
      handleExams(response);
    });
  };

  const handleDelete = async (id) => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.deleteAmplifierExam(id).then(queryExams);
  };

  const formatPowerResults = (results) => {
    let tempPowerResults = [];
    let xs = [];
    let ys = [];
    results.forEach((result) => {
      xs.push(parseFloat(result.input_power));
      ys.push(parseFloat(result.output_power));
      tempPowerResults.push({
        index: result.index,
        inputPower: parseFloat(result.input_power),
        outputPower: parseFloat(result.output_power),
        readValue: parseFloat(result.read_value),
        gain: parseFloat(result.gain),
      });
    });
    return tempPowerResults;
  };

  const formatSaturationResults = (results) => {
    let data = [];
    results.forEach((result) => {
      data.push({
        index: result.index,
        frequency: parseFloat(result.frequency),
        saturationInput: parseFloat(result.saturation_input),
        saturationOutput: parseFloat(result.saturation_output),
        compressionInput: parseFloat(result.compression_input),
        compressionOutput: parseFloat(result.compression_output),
        specifiedOutput: parseFloat(result.specified_output).toFixed(
          MAX_PRECISION
        ),
        gain: (
          parseFloat(result.saturation_output) -
          parseFloat(result.saturation_input).toFixed(MAX_PRECISION)
        ).toFixed(MAX_PRECISION),
      });
    });
    return data;
  };

  const getPowerExam = async (powerExamId, index) => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter
      .getExamResults("power_exam", powerExamId)
      .then((results) => {
        if (results) {
          let formattedResults = formatPowerResults(results);
          allPreviousGainTests.current[index].results = nestedCopy(
            formattedResults
          );
          setPrevPowerData(allPreviousGainTests.current[index].results);
          setResetGainChart(true);
        }
      });
  };

  const setUpPowerExams = (powerExams) => {
    allPreviousGainTests.current = nestedCopy(powerExams);
    chartIndex.current = 0;
    getPowerExam(powerExams[0].id, 0);
  };

  const clear = () => {
    chartIndex.current = -1;
    allPreviousGainTests.current = [];
    setSatData([]);
    setPrevPowerData([]);
  };

  const handleResetGainChart = () => {
    setResetGainChart(!resetGainChart);
  };

  const handleResetSaturationChart = () => {
    setResetSatChart(!resetSatChart);
  };

  const handleChartChange = async (pastExam) => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter
      .getExamResults("sat_exams", pastExam.id)
      .then((response) => {
        if (response) {
          let formattedResults = formatSaturationResults(response.results);
          setCheckBoxes(nestedCopy(saturationExamCheckboxes));
          setSatData(formattedResults);
          setUpPowerExams(response.results);
        }
      });
  };

  const viewNext = () => {
    if (chartIndex.current + 1 < allPreviousGainTests.current.length) {
      chartIndex.current += 1;
      if (!allPreviousGainTests.current[chartIndex.current].results) {
        getPowerExam(
          allPreviousGainTests.current[chartIndex.current].id,
          chartIndex.current
        );
      } else {
        setPrevPowerData(
          allPreviousGainTests.current[chartIndex.current].results
        );
        setResetGainChart(true);
      }
    }
  };

  const viewPrevious = () => {
    if (chartIndex.current - 1 > -1) {
      chartIndex.current -= 1;
      if (!allPreviousGainTests.current[chartIndex.current].results) {
        getPowerExam(
          allPreviousGainTests.current[chartIndex.current].id,
          chartIndex.current
        );
      } else {
        setPrevPowerData(
          allPreviousGainTests.current[chartIndex.current].results
        );
        setResetGainChart(true);
      }
    }
  };

  const heading = () => (
    <Grid container justifyContent="center" alignContent="center">
      <Paper elevation={3}>
        <Typography variant="h5">
          {name.current}: {item.current.name}
        </Typography>
        <Typography variant="h6">
          Serial Number: {item.current.serial}
        </Typography>
      </Paper>
    </Grid>
  );

  const left = () => (
    <>
      <IconButton
        onClick={viewPrevious}
        disabled={!chartIndex.current ? true : false}
      >
        <ArrowBackIos />
      </IconButton>
    </>
  );

  const right = () => (
    <>
      <IconButton
        onClick={viewNext}
        disabled={
          chartIndex.current ===
            allPreviousGainTests.current[chartIndex.current].length - 1
            ? true
            : false
        }
      >
        <ArrowForwardIos />
      </IconButton>
    </>
  );

  const previousPowerExam = () => {
    let lines = { ...PowExamLines, dots: [] };
    let compressionPoint = {
      ...compPointDot,
      x: allPreviousGainTests.current[chartIndex.current].compression_input,
      y: allPreviousGainTests.current[chartIndex.current].compression_output,
    };
    let saturationPoint = {
      ...satPointDot,
      x: allPreviousGainTests.current[chartIndex.current].saturation_input,
      y: allPreviousGainTests.current[chartIndex.current].saturation_output,
    };
    lines.dots.push(compressionPoint);
    lines.dots.push(saturationPoint);
    return (
      <>
        <Chart
          filename={
            "power_exam" +
            parseFloat(
              allPreviousGainTests.current[chartIndex.current].frequency
            ).toFixed(MAX_PRECISION)
          }
          chartLabel={`${parseFloat(
            allPreviousGainTests.current[chartIndex.current].frequency
          ).toFixed(MAX_PRECISION)}
      GHz`}
          headers={powerExamHeaders}
          unit="dBm"
          lines={lines}
          checkBoxes={nestedCopy(compressionCheckboxes)}
          showCheckBoxes={false}
          chartData={prevPowerData}
          resetChart={resetGainChart}
          handleResetChart={handleResetGainChart}
        />
      </>
    );
  };

  useEffect(() => {
    let examAPIRouter = new ExamAPIRouter();
    examAPIRouter.getAmplifierExams(item.current.id).then((response) => {
      handleExams(response);
    });
    return () => { };
  }, []);

  return !exams ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {heading()}
      {allPreviousGainTests.current.length ? (
        <>
          {left()}
          {right()}
          {previousPowerExam()}
        </>
      ) : null}
      {satData.length || prevPowerData.length ? (
        <>
          <Typography variant="h5">Saturation Test</Typography>
          <Chart
            filename={"sat_exam_chart"}
            chartLabel={"Saturation Test"}
            lines={{ ...SatExamLines }}
            headers={satExamHeaders}
            chartData={satData}
            reset
            columns={saturationColumns}
            unit="GHz"
            checkBoxes={checkBoxes}
            showCheckBoxes={true}
            resetChart={resetSatChart}
            handleResetChart={handleResetSaturationChart}
          />
          <Controls.ChartButtons
            inProcess={false}
            clear={clear}
            data={satData}
          />
        </>
      ) : null}
      {satData.length || prevPowerData.length ? null : (
        <Table
          headCells={saturationExamHeadCells}
          tableItems={exams}
          name={"Saturation Exams"}
          tableCount={totalExams}
          searchByValue={"amplifier_serial"}
          handleDelete={handleDelete}
          handleChartChange={handleChartChange}
          tableButtons={Controls.TableExamResultsButtons}
        />
      )}
    </>
  );
}
