/**
 *  Buttons for the exam results table.
 */
import React, { useState } from "react";
import Controls from "./Controls";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CloseIcon from "@mui/icons-material/Close";
import DeleteExamForm from "../Form/DeleteExamForm";

export default function TableExamResultsButtons(props) {
  const { item, handleChartChange, handleDelete } = props;
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const deleteItem = () => {
    setOpenDeletePopup(true);
  };

  const popUps = () => (
    <>
      <Controls.Popup
        title={`Deleting a Saturation exam`}
        openPopup={openDeletePopup}
        setOpenPopup={setOpenDeletePopup}
      >
        <DeleteExamForm
          item={item}
          handleDelete={handleDelete}
          closePopup={() => {
            setOpenDeletePopup(false);
          }}
        />
      </Controls.Popup>
    </>
  );

  return (
    <>
      <Controls.ActionButton
        color="primary"
        onClick={() => {
          handleChartChange(item);
        }}
      >
        <ShowChartIcon fontSize="small" />
      </Controls.ActionButton>
      <Controls.ActionButton color="error" onClick={() => deleteItem(item)}>
        <CloseIcon fontSize="small" />
      </Controls.ActionButton>
      {popUps()}
    </>
  );
}
