import React, { useEffect, useRef, useState } from "react";
import { nestedCopy } from "../controls/Constants";
import Chart from "../Chart/Chart";
import TableFullExamButtons from "../controls/TableFullExamButtons";
import Table from "../Table/Table";
import Controls from "../controls/Controls";
import { Typography } from "@mui/material";

export default function FullExamStep(props) {
  const {
    changeMessage,
    // clearMessage,
    selections,
    setSelections,
    label,
    tableTitle,
    query,
    queryParameters,
    charts,
    lines,
    columns,
    checkBoxes,
    tableHeadCells,
    getResults,
    formatResults,
    handleComplete,
    handleSingleReset,
  } = props;
  const [chartsData, setChartsData] = useState([]);
  const [tableChartsData, setTableChartData] = useState([]);
  const [showTableCharts, setShowTableCharts] = useState(false);
  const [table, setTable] = useState([]);
  let additionalParams = useRef({ ...queryParameters.additional });

  const handleQuery = (data) => {
    if (data) setTable(data);
  };

  const clearSelection = (step) => {
    let updatedSelections = { ...selections };
    delete updatedSelections[step];
    setSelections({ ...updatedSelections });
    handleSingleReset();
  };

  const hideSelection = (step) => {
    let updatedSelections = { ...selections };
    for (const selection in selections) {
      if (selection === step) {
        updatedSelections[step].showChart = false;
      }
    }
    setSelections({ ...updatedSelections });
  };

  const showSelection = (step) => {
    let updatedSelections = { ...selections };
    for (const selection in selections) {
      if (selection === step) {
        updatedSelections[step].showChart = true;
      }
    }
    setSelections({ ...updatedSelections });
  };

  const updateTableChartData = (label, step, type, results) => {
    let updateTableChartData = [];
    for (const chart in charts) {
      updateTableChartData.push({
        label: label,
        step: step,
        type: type,
        data: nestedCopy(formatResults[type](results)),
      });
    }
    setTableChartData(updateTableChartData);
  };

  const updateChartData = (label, step, type, results) => {
    let updatedChartData = [];
    updatedChartData.push({
      label: label,
      step: step,
      type: type,
      data: nestedCopy(formatResults[type](results)),
    });
    setChartsData(updatedChartData);
  };

  const handleSubmit = (item) => {
    setSelections({
      ...selections,
      [label.split(" ")[0].toLowerCase()]: { showChart: true, ...item },
    });
    getChartResults(item);
    handleComplete();
  };

  const formatParameters = (exam) => {
    for (const key of Object.keys(queryParameters.additional)) {
      if (selections[key]) additionalParams.current[key] = selections[key];
      else additionalParams.current[key] = exam;
    }
  };

  const getChartResults = async (pastExam) => {
    formatParameters(pastExam);
    for (const chart of charts) {
      // await getResults[chart.type](queryParameters, pastExam).then(
      await getResults[chart.type](queryParameters, pastExam).then(
        (results) => {
          if (results) {
            updateChartData(
              chart.label,
              chart.label.split(" ")[0].toLowerCase(),
              chart.type,
              results
            );
          } else {
            changeMessage("No results found.", "#F08080");
          }
        }
      );
    }
  };

  const handleChartChange = async (pastExam) => {
    formatParameters(pastExam);
    for (const chart of charts) {
      // await getResults[chart.type](queryParameters, pastExam).then(
      await getResults[chart.type](additionalParams.current).then((results) => {
        if (results) {
          updateTableChartData(
            chart.label,
            chart.label.split(" ")[0].toLowerCase(),
            chart.type,
            results
          );
          setShowTableCharts(true);
        } else {
          changeMessage("No results found.", "#F08080");
        }
      });
    }
  };

  const renderTable = () => (
    <Table
      headCells={tableHeadCells}
      tableItems={table}
      tableCount={table.length}
      name={tableTitle ? tableTitle : label}
      handleChartChange={handleChartChange}
      handleSubmit={handleSubmit}
      tableButtons={TableFullExamButtons}
    />
  );

  const renderTableChartData = () => (
    <>
      {tableChartsData.map((chartData, index) => {
        return (
          <div key={`selection${index}`}>
            <Typography>Table {chartData.label}</Typography>
            <Controls.Button
              text={showTableCharts ? "Hide" : "Show Charts"}
              onClick={() => {
                setShowTableCharts(!showTableCharts);
              }}
            />
            <Controls.Button
              text={"Clear Table Data"}
              onClick={() => {
                setTableChartData([]);
              }}
            />
            {showTableCharts ? (
              <Chart
                chartData={chartData.data}
                columns={columns[chartData.type]}
                lines={lines[chartData.type]}
                checkBoxes={checkBoxes[chartData.type].checkBoxes}
                showCheckBoxes={checkBoxes[chartData.type].showCheckBoxes}
              />
            ) : null}
          </div>
        );
      })}
    </>
  );
  const renderChartData = () => (
    <>
      {chartsData.map((chartData, index) => {
        if (
          selections[chartData.step] &&
          label === chartData.label &&
          selections[chartData.step].showChart
        ) {
          return (
            <div key={`selection${index}`}>
              <Typography variant="h6">Selected {chartData.label}</Typography>
              <Controls.Button
                text="Hide"
                onClick={() => {
                  hideSelection(chartData.step);
                }}
              />
              <Controls.Button
                text="Clear Selection"
                onClick={() => {
                  clearSelection(chartData.step);
                }}
              />
              <Chart
                chartData={chartData.data}
                columns={columns[chartData.type]}
                lines={lines[chartData.type]}
                checkBoxes={checkBoxes[chartData.type].checkBoxes}
                showCheckBoxes={checkBoxes[chartData.type].showCheckBoxes}
              />
            </div>
          );
        } else if (label === chartData.label && selections[chartData.step]) {
          return (
            <div key={`Show${index}`}>
              <Typography>Selected {chartData.label}</Typography>
              <Controls.Button
                text="Show"
                onClick={() => {
                  showSelection(chartData.step);
                }}
              />
              <Controls.Button
                text="Clear Selection"
                onClick={() => {
                  clearSelection(chartData.step);
                }}
              />
            </div>
          );
        }
      })}
    </>
  );
  useEffect(() => {
    if (query) {
      (async () => {
        await query(queryParameters.endpoint).then((data) => {
          handleQuery(data);
        });
      })();
      // setChartsData([]);
    }
  }, [queryParameters]);

  return (
    <>
      {label}
      {renderChartData()}
      {renderTableChartData()}
      {renderTable()}
    </>
  );
}
