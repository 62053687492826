/**
 * Types for authorization
 */
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_REFRESH = "AUTHENICATED_REFRESH";
export const AUTHENTICATED_REFRESH_SUCCESS = "AUTHENICATED_REFRESH_SUCCESS";
export const AUTHENTICATED_REFRESH_FAIL = "AUTHENICATED_REFRESH_FAIL";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const LOGOUT = "LOGOUT";
