/**
 *  Styling for selects in forms.
 */
import MuiFormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { getNestedValues } from "./Constants";

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
  margin: theme.spacing.apply(2),
}));

export default function Select(props) {
  const {
    values,
    errors,
    menuItems,
    menuItemName,
    handleInputChange,
    setValues,
    instruments,
    menuItemTextValue,
    disabled,
    defaultItem,
    label,
    changeInputsOnSelect,
  } = props;
  const [changeDefault, setChangeDefault] = useState(false);
  const changeInstruments = (e) => {
    if (e.target.name === "stand") {
      handleInputChange(e);
      let allInstruments = e.target.value;
      for (const instrument in allInstruments) {
        if (instrument in values && instrument !== "stand") {
          setValues({ ...values, [instrument]: allInstruments[instrument] });
        }
      }
    } else {
      handleInputChange(e);
    }
  };
  const changeInputs = (e, item, itemName) => {
    handleInputChange(e);
    setValues({
      ...values,
      ...getNestedValues(e.target.value, values),
      [item]: e.target.value,
    });
  };
  return (
    <FormControl style={{ minWidth: 120 }}>
      <FormGroup row>
        <InputLabel shrink>{label}</InputLabel>
        <MuiSelect
          name={menuItemName}
          variant="outlined"
          id={menuItemName}
          fullWidth
          disabled={disabled ? true : false}
          value={
            defaultItem && !changeDefault ? defaultItem : values[menuItemName]
          }
          error={errors[menuItemName]}
          onChange={(e) => {
            if (instruments) changeInstruments(e);
            else {
              if (changeInputsOnSelect)
                changeInputs(e, menuItemName, menuItemTextValue);
              else {
                setChangeDefault(true);
                handleInputChange(e);
              }
            }
          }}
        >
          {defaultItem && !changeDefault ? (
            <MenuItem value={defaultItem}>
              {defaultItem[menuItemTextValue]
                ? defaultItem[menuItemTextValue]
                : defaultItem}
            </MenuItem>
          ) : null}
          {menuItems.map((menuItem, index) => {
            // if (
            //   defaultItem &&
            //   defaultItem[menuItemTextValue] === menuItem[menuItemTextValue]
            // )
            //   return null;
            // else
            return (
              <MenuItem key={{ menuItemName } + index} value={menuItem}>
                {menuItem[menuItemTextValue]
                  ? menuItem[menuItemTextValue]
                  : menuItem !== undefined
                  ? menuItem
                  : null}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormGroup>
    </FormControl>
  );
}
