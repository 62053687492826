import React, { useEffect, useState } from "react";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import Table from "../Table/Table";
import { amplifierHeadCells, newAmplifierForm } from "../Catalog/CataConstants";
import Controls from "../controls/Controls";
import { changeFormLayout, nestedCopy } from "../controls/Constants";

export default function Amplifiers() {
  const [amplifiers, setAmplifiers] = useState(null);
  const [totalAmplifiers, setTotalAmplifiers] = useState(0);
  const [formLayout, setFormLayout] = useState(
    nestedCopy(newAmplifierForm.formLayout)
  );
  const handleAmplifiers = (response) => {
    if (!response) {
      setAmplifiers([]);
      setTotalAmplifiers(0);
    } else {
      setAmplifiers(response);
      setTotalAmplifiers(response.length);
    }
  };
  const queryAmplifiers = async () => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter.getCatalogItems("amplifiers").then((response) => {
      handleAmplifiers(response);
    });
  };
  const handleEdit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newAmplifier = {
      serial: values.serial,
      model: values.model,
      name: values.name,
      specified_power: parseFloat(values.specifiedPower),
      max_duty_cycle: parseFloat(values.maxDutyCycle),
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      old_main: values.oldMain,
      continuous_wave: values.continuousWave,
    };
    return await catalogApiRouter
      .updateCatalogItem("amplifiers", values.id, newAmplifier)
      .then(queryAmplifiers);
  };

  const handleSubmit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newAmplifier = {
      serial: parseInt(values.serial),
      model: parseInt(values.model),
      name: values.name,
      specified_power: parseFloat(values.specifiedPower),
      max_duty_cycle: parseFloat(values.maxDutyCycle),
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      old_main: values.oldMain,
      continuous_wave: values.continuousWave,
    };
    await catalogApiRouter
      .postCatalogItem("amplifiers", newAmplifier)
      .then(queryAmplifiers);
  };

  const handleDelete = async (id) => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter
      .deleteCatalogItem("amplifiers", id)
      .then(queryAmplifiers);
  };

  useEffect(() => {
    let catalogApiRouter = new CatalogAPIRouter();
    catalogApiRouter.getCatalogItems("amplifiers").then((results) => {
      handleAmplifiers(results);
    });
    return () => {};
  }, []);

  return !amplifiers ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Table
      headCells={amplifierHeadCells}
      tableItems={amplifiers}
      tableButtons={Controls.TableCatalogButtons}
      formButtons={Controls.CatalogButtons}
      initialValues={{ ...newAmplifierForm.initialValues }}
      formLayout={formLayout}
      setFormLayout={setFormLayout}
      changeFormLayout={changeFormLayout}
      name="Amplifier"
      handleSubmit={handleSubmit}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      tableCount={totalAmplifiers}
    />
  );
}
