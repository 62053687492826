/**
 * Styling for chart checkboxes.
 */
import React from "react";
import FormLabel from "@mui/material/FormLabel";
import MuiFormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
/* import FormHelperText from "@mui/material/FormHelperText"; */
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  userSelect: "none",
}));

const FormControl = styled(MuiFormControl)(({ theme }) => ({
  userSelect: "none",
  margin: theme.spacing(3),
}));

export default function ChartCheckboxes(props) {
  const { checkBoxes, state, handleChange } = props;
  return (
    <Container>
      <FormControl component="fieldset">
        <FormLabel component="legend">Lines</FormLabel>
        <FormGroup>
          {checkBoxes.map((checkBox, index) => {
            return (
              <FormControlLabel
                key={"checkbox" + index}
                control={
                  <Checkbox
                    checked={state[checkBox.id]}
                    onChange={handleChange}
                    name={checkBox.id}
                  />
                }
                label={checkBox.label}
              />
            );
          })}
        </FormGroup>
        {/* <FormHelperText>Be careful</FormHelperText> */}
      </FormControl>
    </Container>
  );
}
