/**
 * Constants for catalog forms and tables.
 */
import PowerHeadForm from "../Form/PowerHeadForm";

export const amplifierHeadCells = [
  {
    id: "serial",
    label: "Serial Number",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "name", label: "Manufacturer" },
  {
    id: "frequency_range",
    label: "Frequency Range",
    disableSorting: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const cableHeadCells = [
  {
    id: "serial",
    label: "Serial Number",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "name", label: "Manufacturer" },
  {
    id: "frequency_range",
    label: "Frequency Range",
    disableSorting: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const couplerHeadCells = [
  {
    id: "serial",
    label: "Serial Number",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "name", label: "Manufacturer" },
  { id: "specified_attenuation", label: "Spec Attenuation" },
  {
    id: "frequency_range",
    label: "Frequency Range",
    disableSorting: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const powerHeadCells = [
  {
    id: "serial",
    label: "Serial Number",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "name", label: "Manufacturer" },
  { id: "pulsing", label: "Pulsing" },
  { id: "power_range", label: "Power Range" },
  {
    id: "frequency_range",
    label: "Frequency Range",
    disableSorting: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const newAmplifierTextFields = [
  { id: "serial", label: "Serial" },
  { id: "name", label: "Name" },
  { id: "model", label: "Model" },
  { id: "specifiedPower", label: "Spec. Power" },
  { id: "maxDutyCycle", label: "Duty Cycle" },
  { id: "lowFrequency", label: "Low Freq.|GHz" },
  { id: "highFrequency", label: "High Freq.|GHz" },
];
export const newCableTextFields = [
  { id: "lowFrequency", label: "Low Freq.|GHz" },
  { id: "highFrequency", label: "High Freq.|GHz" },
  { id: "serial", label: "Serial" },
  { id: "name", label: "Name" },
];
export const newCouplerTextFields = [
  ...newCableTextFields,
  { id: "specifiedAttenuation", label: "Spec. Atten|dBm" },
];
export const newPowerHeadTextFields = [
  { id: "serial", label: "Serial" },
  { id: "name", label: "Manufacturer" },
  { id: "lowFrequency", label: "Low Freq.|GHz" },
  { id: "highFrequency", label: "High Freq.|GHz" },
  { id: "lowPower", label: "Low Power|dBm" },
  { id: "highPower", label: "High Power|dBm" },
];
export const calibrationFactorTextFields = [
  { id: "frequency", label: "Frequency|GHz" },
  { id: "factor", label: "Cal Factor|%" },
  { id: "rho", label: "RHO" },
];

export const newCableForm = {
  initialValues: {
    name: "",
    serial: "",
    lowFrequency: "",
    highFrequency: "",
  },
  formLayout: [
    {
      type: "textfields",
      fields: newCableTextFields,
      fieldsName: "Calibration Text Fields",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const newCouplerForm = {
  initialValues: {
    name: "",
    serial: "",
    lowFrequency: "",
    highFrequency: "",
    specifiedAttenuation: "",
  },
  formLayout: [
    {
      type: "textfields",
      fields: newCouplerTextFields,
      fieldsName: "Calibration Text Fields",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const newAmplifierForm = {
  initialValues: {
    serial: "",
    model: "",
    name: "",
    specifiedPower: "",
    maxDutyCycle: "",
    lowFrequency: "",
    highFrequency: "",
    oldMain: false,
    continuousWave: false,
  },
  formLayout: [
    {
      type: "textfields",
      fields: newAmplifierTextFields,
      fieldsName: "Amplifier Text Fields",
    },
    {
      type: "checkbox",
      title: "Old Main",
      name: "oldMain",
      value: false,
    },
    {
      type: "checkbox",
      title: "Continuous Wave",
      name: "continuousWave",
      value: false,
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const newPowerHeadForm = {
  initialValues: {
    serial: "",
    name: "",
    pulsing: false,
    lowPower: "",
    highPower: "",
    lowFrequency: "",
    highFrequency: "",
    calFactor: [],
  },
  formLayout: [
    {
      type: "textfields",
      fields: newPowerHeadTextFields,
      fieldsName: "Power Head Text Fields",
    },
    {
      type: "checkbox",
      title: "Pulsing",
      name: "pulsing",
      value: false,
    },
    {
      type: "additionals",
      title: "Calibration Factors",
      name: "calFactor",
      form: PowerHeadForm,
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const selectPowerHeadForm = (burnIn = false) => {
  return {
    initialValues: {
      ...newPowerHeadForm.initialValues,
      powerHead: "",
      dutyCycle: "",
      meterDelay: burnIn ? 1 : null,
      readDelay: 1,
      toggleP1db: false,
      inputOffset: 0,
      outputOffset: 0,
    },
    formLayout: [
      {
        type: "select",
        menuItems: [],
        menuItemName: "powerHead",
        defaultItem: null,
        menuItemTextValue: "serial",
        label: "Power Head",
        changeInputsOnSelect: true,
      },
      {
        type: "textfields",
        fields: burnIn
          ? [
              { id: "dutyCycle", label: "Duty Cycle|%" },
              {
                id: "meterDelay",
                label: "Meter Delay|sec(s)",
                toolTip: "Delay between each power meter read.",
              },
            ]
          : [
              { id: "dutyCycle", label: "Duty Cycle|%" },
              {
                id: "readDelay",
                label: "Read Delay|sec(s)",
                toolTip: "Delay between each read.",
              },
            ],
        fieldsName: "Duty Cycle ",
      },
      {
        type: "textfields",
        fields: newPowerHeadTextFields,
        fieldsName: "Power Head Text Fields",
      },
      {
        type: "checkbox",
        title: "Pulsing",
        name: "pulsing",
        value: false,
      },
      {
        type: "slider",
        title: "Input Offset",
        name: "inputOffset",
        value: 0,
      },
      {
        type: "slider",
        title: "Output Offset",
        name: "outputOffset",
        value: 0,
      },
      burnIn
        ? null
        : {
            type: "toggle",
            label: "Toggle P1dB Exams",
            name: "toggleP1db",
          },
      {
        type: "additionals",
        title: "Calibration Factors",
        name: "calFactor",
        form: PowerHeadForm,
      },
      {
        type: "buttons",
      },
    ],
    storedValues: null,
  };
};
