import React, { useEffect, useState } from "react";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import Table from "../Table/Table";
import { cableHeadCells, newCableForm } from "../Catalog/CataConstants";
import Controls from "../controls/Controls";
import { changeFormLayout, nestedCopy } from "../controls/Constants";

export default function Cables() {
  const [cables, setCables] = useState(null);
  const [totalCables, setTotalCables] = useState(0);
  const [formLayout, setFormLayout] = useState(
    nestedCopy(newCableForm.formLayout)
  );
  const handleCables = (response) => {
    if (!response) {
      setCables([]);
      setTotalCables(0);
    } else {
      setCables(response);
      setTotalCables(response.length);
    }
  };
  const queryCables = async () => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter.getCatalogItems("cables").then((response) => {
      handleCables(response);
    });
  };
  const handleEdit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newCable = {
      serial: values.serial,
      name: values.name,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
    };
    return await catalogApiRouter
      .updateCatalogItem("cables", values.id, newCable)
      .then(queryCables);
  };

  const handleSubmit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newCable = {
      name: values.name,
      serial: values.serial,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
    };
    await catalogApiRouter
      .postCatalogItem("cables", newCable)
      .then(queryCables);
  };

  const handleDelete = async (id) => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter.deleteCatalogItem("cables", id).then(queryCables);
  };

  useEffect(() => {
    let catalogApiRouter = new CatalogAPIRouter();
    catalogApiRouter.getCatalogItems("cables").then((results) => {
      handleCables(results);
    });
    return () => {};
  }, []);

  return !cables ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Table
      headCells={cableHeadCells}
      tableItems={cables}
      tableButtons={Controls.TableCatalogButtons}
      formButtons={Controls.CatalogButtons}
      initialValues={{ ...newCableForm.initialValues }}
      formLayout={formLayout}
      setFormLayout={setFormLayout}
      changeFormLayout={changeFormLayout}
      name="Cable"
      handleSubmit={handleSubmit}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      tableCount={totalCables}
    />
  );
}
