import React, { useEffect, useRef, useState } from "react";
import {
  CalibrationLines,
  cableCalibrationCheckboxes,
  couplerCalibrationCheckboxes,
} from "../Chart/ChartConstants";
import { useLocation } from "react-router-dom";
import {
  changeFormLayout,
  handleContinue,
  handleStop,
  nestedCopy,
  setUpSocket,
  updateTimer,
} from "../controls/Constants";
import MemoryIcon from "@mui/icons-material/Memory";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { CalibrationAPIRouter } from "../Routers/CalibrationAPIRouter";
import { openSocket, sendSocketMessage } from "../controls/WebSocket";
import {
  formatCalResults,
  heading,
  popups,
  cableCalibrationForm,
  couplerCalibrationForm,
  calibrationHeadCells,
  cableCalibrationCsvHeaders,
  couplerCalibrationCsvHeaders,
} from "./CalConstants";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { MissingCalibrations } from "./MissingCalibrations";
import Controls from "../controls/Controls";
import Chart from "../Chart/Chart";
import Table from "../Table/Table";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import { connect } from "react-redux";

// export default function ItemCalibration(props) {
function Calibration(props) {
  const { changeMessage, clearMessage, user, fullExam } = props;
  const location = useLocation();
  let _initialFormLayout = useRef(nestedCopy(cableCalibrationForm.formLayout));
  _initialFormLayout.current[1].disabled = false;
  let _initialFormValues = useRef({ ...cableCalibrationForm.initialValues });

  let socket = useRef(null);
  let missingCalibrations = useRef(null);
  const [viewTable, setViewTable] = useState("cable");
  const [cableCals, setCableCals] = useState([]);
  const [couplerCals, setCouplerCals] = useState([]);
  const [totalCableCals, setTotalCableCals] = useState(0);
  const [totalCouplerCals, setTotalCouplerCals] = useState(0);
  const [calData, setCalData] = useState([]);
  const [calMetaData, setCalMetaData] = useState(null);
  const [cable, setCable] = useState(null);
  const [initValues, setInitValues] = useState({
    ..._initialFormValues.current,
  });
  const [formLayout, setFormLayout] = useState(
    nestedCopy(_initialFormLayout.current)
  );
  if (fullExam && viewTable !== fullExam) {
    if (fullExam === "cable") {
      _initialFormLayout.current = nestedCopy(cableCalibrationForm.formLayout);
      _initialFormLayout.current[1].disabled = false;
      _initialFormValues.current = { ...cableCalibrationForm.initialValues };
    } else {
      _initialFormLayout.current = nestedCopy(
        couplerCalibrationForm.formLayout
      );
      _initialFormLayout.current[2].disabled = false;
      _initialFormValues.current = { ...couplerCalibrationForm.initialValues };
    }
    setViewTable(fullExam);

    setInitValues({
      ..._initialFormValues.current,
    });
    setFormLayout(nestedCopy(_initialFormLayout.current));
  }
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [stopCal, setStopCal] = useState(false);
  const [resetChart, setResetChart] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [newCal, setNewCal] = useState(false);
  const [disconnected, setDisconnect] = useState(false);
  const [closed, setClosed] = useState(false);
  const [item, setItem] = useState(null);

  const clear = (repeat) => {
    if (!repeat) {
      setStopCal(false);
      setInProcess(false);
      setResetChart(false);
      setDisconnect(false);
      setItem(null);
      setCalMetaData(null);
      setCheckBoxes(
        viewTable === "cable"
          ? cableCalibrationCheckboxes
          : couplerCalibrationCheckboxes
      );
    }
    setCalData([]);
    clearMessage();
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }
  };

  const handleCalibrations = (response) => {
    if (!response) {
      setCableCals([]);
      setCouplerCals([]);
    } else {
      setTotalCableCals(response.results.cable_calibrations.length);
      setTotalCouplerCals(response.results.coupler_calibrations.length);
      setCableCals(response.results.cable_calibrations);
      setCouplerCals(response.results.coupler_calibrations);
    }
  };

  const updateData = (newData) => {
    setCalData((prevData) => [
      ...prevData,
      {
        index: newData.index,
        frequency: parseFloat(newData.frequency),
        attenuation: parseFloat(newData.attenuation),
        readValue: parseFloat(newData.read_value),
        previous: parseFloat(newData.previous),
        difference: parseFloat(newData.difference),
        cableAtten: isNaN(newData.cableAtten) ? null : parseFloat(newData.cableAtten),
      },
    ]);
    handleResetChart();
  };

  const handleDisconnect = (disconnect) => {
    if (disconnect.status) {
      setDisconnect(true);
    }
    changeMessage(disconnect.message, "#F08080");
  };

  const handleSocketMessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    if (dataFromServer) {
      if (dataFromServer.error) {
        changeMessage(dataFromServer.error, "#F08080");
      } else if (dataFromServer.cable) {
        setInProcess(false);
        setCable(dataFromServer.cable);
        missingCalibrations.current = dataFromServer.cable;
        setOpenPopup(true);
      } else if (dataFromServer.past_data) {
        setCalData(dataFromServer.past_data);
        handleResetChart();
      } else if (dataFromServer.new_data) {
        updateData(dataFromServer.new_data);
        updateTimer(changeMessage, dataFromServer.etc);
      } else if (dataFromServer.saved) {
        let calibrationAPIRouter = new CalibrationAPIRouter();
        calibrationAPIRouter
          .getLatestCalibrationsForItem(
            viewTable === "cable" ? "cable" : "coupler",
            item.id
          )
          .then((response) => {
            handleCalibrations(response);
          });
      } else if (dataFromServer.status) {
        handleStatus(dataFromServer.status);
      } else if (dataFromServer.disconnect) {
        handleDisconnect(dataFromServer.disconnect);
      }
    }
  };

  const handleStatus = (status) => {
    switch (status) {
      case "started":
        setStopCal(false);
        setInProcess(true);
        break;
      case "stopped":
        setStopCal(true);
        setInProcess(false);
        break;
      case "closed":
        setClosed(true);
        break;
      case "done":
        setInProcess(false);
        setFormLayout(nestedCopy(_initialFormLayout.current));
        changeMessage("Calibration Complete", "#A0D6B4");
        (async () => {
          let calibrationAPIRouter = new CalibrationAPIRouter();
          await calibrationAPIRouter.getAllCalibrations().then((data) => {
            handleCalibrations(data);
          });
        })();
        break;
      default:
    }
  };

  const setUpCal = (values) => {
    setCheckBoxes(
      viewTable === "cable"
        ? cableCalibrationCheckboxes
        : couplerCalibrationCheckboxes
    );
    setItem(values.coupler || values.cable);
    setStopCal(false);
    setNewCal(false);
  };

  const handleSubmit = async (values) => {
    if (!calData.length) {
      setUpCal(values);
      setStopCal(false);
      setNewCal(false);
      if (!socket.current)
        socket.current = setUpSocket(
          "calibration",
          location.state.exam_room,
          user ? user.first_name.toLowerCase() : location.state.user,
          setInProcess,
          handleSocketMessage
        );
      const opened = await openSocket(socket.current);
      if (opened) {
        sendSocketMessage(socket.current, {
          toggle: "start",
          message: {
            stand: values.stand.stand,
            cable: values.cable.id,
            coupler: viewTable === "coupler" ? values.coupler.id : null,
            power_head: viewTable === "coupler" ? values.powerHead.id : null,
            low_freq: values.lowFrequency,
            high_freq: values.highFrequency,
            freq_step: values.freqStep,
            freq_list: values.frequencyList.replace(/\s/g, '').split(','),
            toggle_list: values.toggleList,
            input_power: values.inputPower,
            read_delay: values.readDelay,
            input_offset: 0,
            output_offset: values.outputOffset,
            manual: values.manual,
          },
        });
      } else {
        return;
      }
    }
  };

  const handleDelete = async (id) => {
    let calibrationAPIRouter = new CalibrationAPIRouter();
    await calibrationAPIRouter.deleteCalibration(id);
    await calibrationAPIRouter.getAllCalibrations().then((data) => {
      handleCalibrations(data);
    });
  };

  const handleChartChange = async (pastCalibration) => {
    let calibrationAPIRouter = new CalibrationAPIRouter();
    await calibrationAPIRouter
      .getCalibrationResults(pastCalibration.id)
      .then((results) => {
        if (results) {
          setCheckBoxes(
            viewTable === "cable"
              ? cableCalibrationCheckboxes
              : couplerCalibrationCheckboxes
          );
          setCalData(nestedCopy(formatCalResults(results)));
          handleResetChart();
          setCalMetaData({ ...pastCalibration });
        } else {
          changeMessage("No results found.", "#F08080");
        }
      });
  };

  const handleResetChart = () => {
    setResetChart(!resetChart);
  };

  const queryItems = async () => {
    let catalogAPIRouter = new CatalogAPIRouter();
    let formLayoutCopy = nestedCopy(_initialFormLayout.current);
    await catalogAPIRouter.getCatalogItems("stands").then((data) => {
      if (data) formLayoutCopy[0].menuItems = nestedCopy(data);
    });

    await catalogAPIRouter.getCatalogItems("cables").then((data) => {
      if (data) formLayoutCopy[1].menuItems = nestedCopy(data);
      formLayoutCopy[1].disabled = false;
    });
    if (viewTable === "coupler") {
      await catalogAPIRouter.getCatalogItems("couplers").then((data) => {
        if (data) formLayoutCopy[2].menuItems = nestedCopy(data);
      });
      await catalogAPIRouter.getCatalogItems("power_heads").then((data) => {
        if (data) formLayoutCopy[3].menuItems = nestedCopy(data);
      });
    }
    setFormLayout(nestedCopy(formLayoutCopy));
  };

  const handleNewCalibration = (state) => {
    if (state) queryItems(_initialFormLayout);
    clearMessage();
    setNewCal(state);
  };

  const handleTableChange = (event, newTable) => {
    if (newTable && viewTable !== newTable) {
      setViewTable(newTable);
      let formLayoutCopy = null;
      if (newTable === "cable") {
        formLayoutCopy = nestedCopy(cableCalibrationForm.formLayout);
        formLayoutCopy[1].disabled = false;
        setInitValues({
          ...cableCalibrationForm.initialValues,
        });
      } else {
        formLayoutCopy = nestedCopy(couplerCalibrationForm.formLayout);
        formLayoutCopy[2].disabled = false;
        setInitValues({
          ...couplerCalibrationForm.initialValues,
        });
      }
      _initialFormLayout.current = nestedCopy(formLayoutCopy);
      setFormLayout(nestedCopy(formLayoutCopy));
    }
  };

  useEffect(() => {
    (async () => {
      let calibrationAPIRouter = new CalibrationAPIRouter();
      await calibrationAPIRouter.getAllCalibrations().then((data) => {
        handleCalibrations(data);
      });
    })();
    return () => {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);

  const headers =
    viewTable === "cable"
      ? cableCalibrationCsvHeaders
      : couplerCalibrationCsvHeaders;

  const buttons = [
    { text: "cable", icon: <SettingsInputComponentIcon /> },
    { text: "coupler", icon: <MemoryIcon /> },
  ];

  return !cableCals || !couplerCals ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {heading(viewTable === "cable" ? "Cable" : "Coupler", item, calMetaData)}
      {calData.length || inProcess || fullExam ? null : (
        <Controls.ToggleButtons
          value={viewTable}
          onChange={handleTableChange}
          buttons={buttons}
        />
      )}
      <MissingCalibrations
        cable={cable}
        parameters={{}}
        // parameters={calibrationParams}
        setCable={setCable}
        test={true}
      />
      {inProcess || calData.length || stopCal ? (
        <>
          {calData.length || inProcess || stopCal ? null : (
            <Grid container justifycurrent="center">
              <Typography variant="h6" color="error">
                No results found for this calibration.
              </Typography>
            </Grid>
          )}
          <Controls.ChartButtons
            inProcess={inProcess}
            clear={clear}
            data={calData}
          />
          <Grid container justifycurrent="center">
            <Controls.CalibrationControls
              inProcess={inProcess}
              handleStop={() => handleStop(socket.current)}
              handleContinue={() => handleContinue(socket.current)}
              stopped={stopCal}
            />
          </Grid>
          <Chart
            filename={"calibrations"}
            lines={{ ...CalibrationLines }}
            headers={headers}
            chartData={calData}
            resetChart={resetChart}
            handleResetChart={handleResetChart}
            checkBoxes={checkBoxes}
            showCheckBoxes={true}
          />
        </>
      ) : (
        <>
          <Table
            headCells={calibrationHeadCells}
            initialValues={initValues}
            formLayout={formLayout}
            setFormLayout={setFormLayout}
            changeFormLayout={changeFormLayout}
            formProcessing={handleNewCalibration}
            handleSubmit={handleSubmit}
            openPopup={newCal}
            tableItems={viewTable === "cable" ? cableCals : couplerCals}
            tableCount={
              viewTable === "cable" ? totalCableCals : totalCouplerCals
            }
            name={
              viewTable === "cable"
                ? "Cable Calibrations"
                : "Coupler Calibrations"
            }
            searchByValue={`${viewTable}_serial`}
            handleDelete={handleDelete}
            handleChartChange={handleChartChange}
            tableButtons={Controls.TableCalResultsButtons}
            formButtons={Controls.CalibrationSubmitButtons}
          />
        </>
      )}
      {popups(openPopup, setOpenPopup, missingCalibrations.current, setInProcess)}
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(Calibration);
