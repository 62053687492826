/**
 * Constant functions.
 */
import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { openSocket, sendSocketMessage } from "./WebSocket";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import { Grid } from "@mui/material";
import Controls from "./Controls";

export const MAX_PRECISION = 5;

export function nestedCopy(array) {
  return JSON.parse(JSON.stringify(array));
}

export const convertTime = (seconds = 0, display = null, showUnit = false) => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  if (display) {
    switch (display) {
      case "hour":
        return `${Number(d / 3600).toFixed(MAX_PRECISION)} ${showUnit ? "hr" : ""
          }`;
        break;
      default:
        break;
    }
  }
  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : "00";
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : "00";
  const sDisplay =
    s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : "00";
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const updateTimer = (changeMessage, secondsLeft) => {
  changeMessage(
    "Estimated time till completion: " + convertTime(secondsLeft),
    "#A0D6B4"
  );
};

const queryItems = async (label) => {
  let catalogAPIRouter = new CatalogAPIRouter();
  await catalogAPIRouter
    .getCatalogItems(
      label
        .toLowerCase()
        .split(" ")
        .join("_") + "s"
    )
    .then((data) => {
      return data;
    });
};

export const getCatalogItems = (initFormLayout) => {
  let formLayoutCopy = nestedCopy(initFormLayout);
  for (const form of formLayoutCopy) {
    if (form.type === "select")
      form.menuItems = nestedCopy(queryItems(form.label));
  }
  return formLayoutCopy;
};

export const changeFormLayout = (values, formLayout, setFormLayout) => {
  let formLayoutCopy = nestedCopy(formLayout);
  for (const value in values) {
    if (values[value] instanceof Object) {
      for (const form of formLayoutCopy) {
        if (form.menuItemName === value && !form.disabled) {
          if (!value) {
            form.defaultItem = values[value];
            break;
          }
          if (form.menuItems.length) {
            let menuItemExists = false;
            for (const menuItem of form.menuItems) {
              if (values[value].serial === menuItem.serial) {
                menuItemExists = true;
              }
            }
            if (!menuItemExists) form.menuItems.push({ ...values[value] });
          } else form.menuItems.push({ ...values[value] });
          form.defaultItem = { ...values[value] };
          break;
        }
      }
    }
  }
  setFormLayout(nestedCopy(formLayoutCopy));
};

export const setUpSocket = (
  exam_type,
  exam_room,
  user,
  setInProcess,
  handleSocketMessage
) => {
  let socket = new W3CWebSocket(
    `ws://${process.env.REACT_APP_WS_URL}/ws/${exam_type}/${exam_room ? exam_room : user
    }/?token=` + localStorage.getItem("access")
  );
  socket.onopen = () => {
    console.log(`${exam_type} socket connected`);
  };
  socket.onclose = () => {
    socket = null;
    setInProcess(false);
    console.log(`Disconnected from ${exam_type} socket`);
  };
  socket.onmessage = (message) => {
    console.log("recieved message", JSON.parse(message.data));
    handleSocketMessage(message);
  };
  return socket;
};

export const joinExam = async (
  socket,
  exam_type,
  exam_room,
  exam_id,
  user,
  setInProcess,
  handleSocketMessage
) => {
  let newSocket = socket;
  if (!newSocket) {
    newSocket = setUpSocket(
      exam_type,
      exam_room,
      user,
      setInProcess,
      handleSocketMessage
    );
  }
  const opened = await openSocket(newSocket);
  if (opened) {
    sendSocketMessage(newSocket, {
      toggle: "join",
      message: exam_id,
    });
  } else {
    console.log("Socket is closed or could not find socket in time.");
  }
  return newSocket;
};

export const disconnectStand = (socket, stand) => {
  if (socket) {
    sendSocketMessage(socket, {
      toggle: "disconnect",
      message: stand,
    });
  }
};

export const handleContinue = (socket, parameters = null) => {
  if (socket) {
    sendSocketMessage(socket, {
      toggle: "continue",
      ...parameters,
    });
  }
};

export const handleStop = (socket) => {
  if (socket) {
    sendSocketMessage(socket, {
      toggle: "stop",
    });
  }
};

export const standButton = (location, socket) => (
  <>
    {location.state ? (
      location.state.stand ? (
        <>
          <Grid container justifyContent={"center"}>
            <Controls.Button
              text="Disconnect Stand"
              color="primary"
              variant="contained"
              onClick={() =>
                disconnectStand(socket.current, location.state.stand)
              }
            />
          </Grid>
        </>
      ) : null
    ) : null}
  </>
);

export const checkNestedProperties = (key, property, parent_key) => {
  if (typeof property[key] === "boolean") {
    if (property[key]) return true;
    return false;
  }
  if (property[key] && !(property[key] instanceof Object)) {
    if (/date/.test(key)) {
      return new Date(property[key]).toLocaleString();
    }
    return property[key];
  }
  if (key === "stand") {
    return property.stand;
  }
  if (key === "cable" || key === "coupler" || key === "amplifier") {
    if (property[key]) return property[key].serial;
    return null;
  }

  if (key === "serial") {
    if (property.coupler) return property.coupler.serial;
    else if (property.cable) return property.cable.serial;
    else return property.serial;
  }
  if (key === "instruments") {
    let instruments = "";
    for (const subProperty in property) {
      if (property[subProperty] instanceof Object && subProperty !== "stand") {
        instruments +=
          subProperty.charAt(0).toUpperCase() +
          subProperty.slice(1) +
          ": " +
          property[subProperty].name +
          " ";
      }
    }
    return instruments;
  }
  if (/date_done/.test(key)) {
    return "Unfinished";
  }
  let result = null;
  if (parent_key === "frequency_parameter" && key === "frequency_range") {
    if (property["frequency_list"]) {
      let tableCellValue = "";
      let count = 0
      for (const frequency of property["listed_freqs"]) {
        if (count) {
          if (count === 4 && property["listed_freqs"].length > 4) {
            tableCellValue += "..."
            return tableCellValue
          }
          tableCellValue += ","
        } else count += 1
        tableCellValue += frequency.frequency
        count += 1
      }
      return tableCellValue
    }
  }
  for (const subProperty in property) {
    if (key === subProperty) {
      if (property[subProperty] instanceof Object) {
        let tableCellValue = "";
        let count = 0;
        for (const subKey in property[subProperty]) {
          if (subKey !== "id") {
            if (count) {
              if (subProperty !== "user") tableCellValue += "-";
              else tableCellValue += " ";
            } else count++;
            tableCellValue += property[subProperty][subKey];
          }
        }
        return tableCellValue;
      }
      return property[subProperty];
    }
    if (property[subProperty] instanceof Object) {
      result = checkNestedProperties(key, property[subProperty], subProperty);
      if (result) break;
    }
  }
  return result;
};

// convert snake case to camel case
function formatKey(key) {
  let splitKey = key.split("_");
  return (
    splitKey[0] + splitKey[1].charAt(0).toUpperCase() + splitKey[1].slice(1)
  );
}

export const getNestedValues = (item, values) => {
  let valuesCopy = { ...values };
  for (const attribute in item) {
    if (item[attribute] instanceof Object) {
      valuesCopy = getNestedValues(item[attribute], valuesCopy);
    } else if (attribute.includes("_")) {
      valuesCopy[formatKey(attribute)] = item[attribute];
    } else if (Object.keys(valuesCopy).includes(attribute))
      valuesCopy[attribute] = item[attribute];
  }
  return valuesCopy;
};

/**
 * Retrieve a fixed number of elements from an array, evenly distributed but
 * always including the first and last elements.
 *
 * @param   {Array} items - The array to operate on.
 * @param   {number} n - The number of elements to extract.
 * @returns {Array}
 */
function distributedCopy(items, n) {
  var elements = [items[0]];
  var totalItems = items.length - 2;
  var interval = Math.floor(totalItems / (n - 2));
  for (var i = 1; i < n - 1; i++) {
    elements.push(items[i * interval]);
  }
  elements.push(items[items.length - 1]);
  return elements;
}

function averageData(data, newData, spacing) {
  // TODO: Average out, gain, output_power, read_value. keep input_power,
  // frequency, specified output and last timestamp, index is now 1.
  let avgInput = 0;
  let avgOutput = 0;
  let avgRead = 0;
  let avgGain = 0;
  let count = 0;
  let arr = [];

  // After 120 points. average out first 120 points
  // 1. Check if modulo of length of the array is of the spacing is equal to 0.
  // 2. If true, average all values then re-index.
  // 3. Else, re-index all values to end of data array.
  if (newData.length % spacing === 0 || data.length === 0) {
    if (newData.length) arr.push(newData[0]);
    for (let i = 1; i < newData.length; i++) {
      if (newData[i].input_power) avgOutput += newData[i].input_power;
      if (newData[i].inputPower) avgInput += newData[i].inputPower;
      if (newData[i].output_power) avgOutput += newData[i].output_power;
      if (newData[i].outputPower) avgOutput += newData[i].outputPower;
      if (newData[i].read_value) avgRead += newData[i].read_value;
      if (newData[i].readValue) avgRead += newData[i].readValue;
      if (newData[i].gain) avgGain += newData[i].gain;
      count += 1;
      if (count % spacing === 0) {
        arr.push({
          index: newData[i].index,
          timestamp: newData[i].timestamp,
          specifiedOutput: newData[i].specifiedOutput,
          inputPower: parseFloat((avgInput / count).toFixed(MAX_PRECISION)),
          outputPower: parseFloat((avgOutput / count).toFixed(MAX_PRECISION)),
          readValue: parseFloat((avgRead / count).toFixed(MAX_PRECISION)),
          gain: parseFloat((avgGain / count).toFixed(MAX_PRECISION)),
          average: true,
        });
        avgInput = 0;
        avgOutput = 0;
        avgRead = 0;
        avgGain = 0;
        count = 0;
      }
    }
    return arr;
  }
  if (data) {
    arr = nestedCopy(data);
    let recentEntry = newData.pop();
    arr.push({ ...recentEntry });
  }
  return arr;
}

export const compressData = (data = null, newData = null) => {
  if (newData === null) return data;
  const spacing = 3600;
  if (newData.length <= spacing) return newData;
  return averageData(nestedCopy(data), nestedCopy(newData), spacing);
};
