/**
 * Example unit status.
 */
export const example_status = {
  analog_values: [
    {
      name: "Vrfr",
      full_name: "RFC Vref",
      low: 0,
      high: 3.35,
      act: 3.313,
      str_low: "0.00 V",
      str_high: "3.35 V",
      str_act: "3.31 V",
      unit: "V",
    },
    {
      name: "Icat",
      full_name: "Cathode",
      low: 0,
      high: 3.080098,
      act: 0.107066,
      str_low: "0.00 A",
      str_high: "3.08 A",
      str_act: "107.07 mA",
      unit: "A",
    },
    {
      name: "Igri",
      full_name: "Grid",
      low: -498.0144828,
      high: 1486.4729984,
      act: 151.2273092,
      str_low: "-498.01 V",
      str_high: "1.49 kV",
      str_act: "151.23 V",
      unit: "V",
    },
    {
      name: "Icol",
      full_name: "Collector",
      low: -1.2127320000000001,
      high: 1.27756,
      act: -0.0038000000000000004,
      str_low: "-1.21 A",
      str_high: "1.28 A",
      str_act: "-3.80 mA",
      unit: "A",
    },
    {
      name: "Vcol",
      full_name: "Collector",
      low: 0,
      high: 36705.593400000005,
      act: 5.040900000000001,
      str_low: "0.00 V",
      str_high: "36.71 kV",
      str_act: "5.04 V",
      unit: "V",
    },
    {
      name: "Ihtr",
      full_name: "Heater",
      low: 0,
      high: 8.5456336,
      act: 2.0119416,
      str_low: "0.00 A",
      str_high: "8.55 A",
      str_act: "2.01 A",
      unit: "A",
    },
    {
      name: "Vdrv",
      full_name: "Drive",
      low: 0,
      high: 534.8950614,
      act: 174.995424,
      str_low: "0.00 V",
      str_high: "534.90 V",
      str_act: "175.00 V",
      unit: "V",
    },
    {
      name: "Vhtr",
      full_name: "Heater",
      low: -1.2000000000000002,
      high: 13.71104,
      act: -6.1097600000000005,
      str_low: "-1.20 V",
      str_high: "13.71 V",
      str_act: "-6.11 V",
      unit: "V",
    },
    {
      name: "Vbdy",
      full_name: "Body",
      low: 0,
      high: 36705.593400000005,
      act: 3.9207,
      str_low: "0.00 V",
      str_high: "36.71 kV",
      str_act: "3.92 V",
      unit: "V",
    },
    {
      name: "Vrfp",
      full_name: "RFC Power Supply",
      low: 0,
      high: 36.338603,
      act: 9.299519499999999,
      str_low: "0.00 V",
      str_high: "36.34 V",
      str_act: "9.30 V",
      unit: "V",
    },
    {
      name: "Trfc",
      full_name: "RFC MCU T",
      low: -148,
      high: 11648.12,
      act: 100.4,
      str_low: "-148.0 °F",
      str_high: "11.6 k°F",
      str_act: "100.4 °F",
      unit: "°F",
    },
    {
      name: "Vmr",
      full_name: "Main Vref",
      low: 2.8000000000000003,
      high: 3.5,
      act: 2.8401,
      str_low: "2.80 V",
      str_high: "3.50 V",
      str_act: "2.84 V",
      unit: "V",
    },
    {
      name: "Prfl",
      full_name: "Reflected Power",
      low: 0,
      high: 65534,
      act: 4677,
      str_low: "0  ",
      str_high: "65534  ",
      str_act: "4677  ",
      unit: " ",
    },
    {
      name: "Lpin",
      full_name: "Pulse in length",
      low: 0,
      high: 2.5340000000000003,
      act: null,
      str_low: "0 s",
      str_high: "2.534 s",
      str_act: null,
      unit: "s",
    },
    {
      name: "Ppin",
      full_name: "Pulse in period",
      low: 0,
      high: 2.5340000000000003,
      act: null,
      str_low: "0 s",
      str_high: "2.534 s",
      str_act: null,
      unit: "s",
    },
    {
      name: "Dpin",
      full_name: "Pulse in duty cycle",
      low: 0,
      high: 2534,
      act: 0,
      str_low: "0 %",
      str_high: "2534 %",
      str_act: "0 %",
      unit: "%",
    },
    {
      name: "FBer",
      full_name: "FBR link error rate",
      low: 0,
      high: 65534,
      act: 0,
      str_low: "0 ",
      str_high: "65534 ",
      str_act: "0 ",
      unit: "",
    },
    {
      name: "Tph2",
      full_name: "Pwhead T2",
      low: -148,
      high: 11648.12,
      act: -148,
      str_low: "-148.0 °F",
      str_high: "11.6 k°F",
      str_act: "-148.0 °F",
      unit: "°F",
    },
    {
      name: "Tpht",
      full_name: "Pwhead tube T",
      low: -148,
      high: 11648.12,
      act: -148,
      str_low: "-148.0 °F",
      str_high: "11.6 k°F",
      str_act: "-148.0 °F",
      unit: "°F",
    },
    {
      name: "Tph1",
      full_name: "Pwhead T",
      low: -148,
      high: 11648.12,
      act: -148,
      str_low: "-148.0 °F",
      str_high: "11.6 k°F",
      str_act: "-148.0 °F",
      unit: "°F",
    },
    {
      name: "Tmch",
      full_name: "Main MCU T",
      low: -148,
      high: 11648.12,
      act: 79.52000000000001,
      str_low: "-148.0 °F",
      str_high: "11.6 k°F",
      str_act: "79.5 °F",
      unit: "°F",
    },
  ],
  states: [
    {
      name: "E0",
      full_name: "ESTAT0, limits set",
      act: true,
    },
    {
      name: "E1",
      full_name: "ESTAT1, limits set",
      act: true,
    },
    {
      name: "RPEn",
      full_name: "RFC Pulses",
      act: false,
    },
    {
      name: "RPLL",
      full_name: "RFC Pulses L lim.",
      act: false,
    },
    {
      name: "RPFL",
      full_name: "RFC Pulses F lim.",
      act: false,
    },
    {
      name: "Rtim",
      full_name: "RFC Timer",
      act: false,
    },
    {
      name: "RPRc",
      full_name: "RFC Pulses Received",
      act: false,
    },
    {
      name: "RPOK",
      full_name: "RFC Pulses within lim.",
      act: false,
    },
    {
      name: "RPop",
      full_name: "RFC Pul. off, P too low",
      act: false,
    },
    {
      name: "RPol",
      full_name: "RFC Pul. off, F too high",
      act: false,
    },
    {
      name: "RCWo",
      full_name: "RFC CW out detected",
      act: false,
    },
    {
      name: "RCWo",
      full_name: "RFC Watchdog reset",
      act: false,
    },
  ],
};
