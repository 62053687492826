/**
 * Styling of chip component for amplifier status.
 */
import React from "react";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { green, red } from "@mui/material/colors";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

export const ChipContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  "& > *": {
    margin: theme.spacing(0.5),
  },
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function Chips(props) {
  const { chips } = props;
  return (
    <ChipContainer>
      {chips.map((chip, index) => {
        return (
          <Tooltip key={chip.name + index} title={chip.full_name} arrow>
            <Chip
              size="small"
              icon={
                chip.act === "On" ||
                chip.act === "Operate" ||
                chip.act === "Standby" ? (
                  <PowerSettingsNewIcon style={{ color: green[500] }} />
                ) : chip.act === "Off" || chip.act === "Power off" ? (
                  <PowerSettingsNewIcon style={{ color: red[500] }} />
                ) : chip.act === "Reset" ? (
                  <RotateLeftIcon style={{ color: "#ff9800" }} />
                ) : chip.act ? (
                  <DoneIcon style={{ color: green[500] }} />
                ) : (
                  <CloseIcon style={{ color: red[500] }} />
                )
              }
              variant="filled"
              color={chip.act ? "primary" : "error"}
              label={chip.name}
            />
          </Tooltip>
        );
      })}
    </ChipContainer>
  );
}
