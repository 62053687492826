/**
 * Constants for calibration/exam charts. Handling lines and dots.
 */
export const couplerCalibrationCheckboxes = [
  { id: "attenuation", label: "Attenuation", active: true },
  { id: "cableAtten", label: "Cable Offset", active: true },
  { id: "previous", label: "Previous Calibration", active: true },
];

export const cableCalibrationCheckboxes = [
  { id: "attenuation", label: "Attenuation", active: true },
  { id: "previous", label: "Previous Calibration", active: true },
];

export const compressionCheckboxes = [
  { id: "outputPower", label: "Output Power", active: true },
  { id: "gain", label: "Gain", active: true },
];

export const saturationExamCheckboxes = [
  { id: "saturationOutput", label: "Saturation", active: true },
  { id: "specifiedOutput", label: "Specified Output", active: true },
  { id: "gain", label: "Gain", active: true },
];

export const burnInCheckboxes = [
  { id: "specifiedOutput", label: "Specified Output", active: true },
  { id: "outputPower", label: "Power Output", active: true },
  { id: "gain", label: "Gain", active: true },
];

export const statusCheckboxes = [{ id: "act", label: "Act", active: true }];

export const compPointDot = {
  x: null,
  y: null,
  color: "#FF8A8A",
  name: "P1dB",
  label: "P1dB",
  unit: "dBm",
  position: "top",
  estY: null,
};

export const satPointDot = {
  x: null,
  y: null,
  color: "#4166F5",
  name: "PSat",
  label: "PSat",
  unit: "dBm",
  position: "bottom",
};

export const CalibrationLines = {
  xAxis: {
    dataKey: "frequency",
    label: "Frequency",
    unit: "dBm",
  },
  yAxis: {
    type: "number",
    label: "Output Power",
    unit: "dBm",
    domain: [null, 0],
  },
  dataKeys: ["attenuation", "cableAtten", "previous"],
  toolTip: {
    label: "Frequency",
    unit: "GHz",
    color: "white",
    enabled: {
      attenuation: true,
      cableAtten: true,
      previous: true,
    },
  },
  labels: {
    frequency: "Frequency",
    attenuation: "Attenuation",
    cableAtten: "Cable Attenuation",
    previous: "Previous",
  },
  units: {
    attenuation: "dBm",
    cableAtten: "dBm",
    previous: "dBm",
  },
  colors: {
    attenuation: "#FF8A8A",
    cableAtten: "orange",
    previous: "black",
  },
  strokeDasharray: {},
};

export const PowExamLines = {
  xAxis: {
    dataKey: "inputPower",
    label: "Input Power",
    unit: "dBm",
  },
  yAxis: {
    type: "number",
    label: "Power Out",
    unit: "dBm",
    domain: [0, null],
  },
  dataKeys: ["outputPower", "gain"],
  toolTip: {
    label: "Input Power",
    unit: "dBm",
    color: "white",
    enabled: {
      outputPower: true,
      gain: true,
    },
  },
  labels: {
    inputPower: "Input Power",
    outputPower: "Output Power",
    gain: "Gain",
  },
  units: {
    outputPower: "dBm",
    gain: "dB",
  },
  colors: {
    outputPower: "#FF8A8A",
    gain: "orange",
  },
  strokeDasharray: {},
};

export const SatExamLines = {
  xAxis: {
    dataKey: "frequency",
    label: "Freqeuncy",
    unit: "GHz",
  },
  yAxis: {
    type: "number",
    label: "Power Out",
    unit: "dBm",
    domain: [0, null],
  },
  dataKeys: ["specifiedOutput", "saturationOutput", "gain"],
  toolTip: {
    label: "Frequency",
    unit: "GHz",
    color: "white",
    enabled: {
      specifiedOutput: true,
      saturationOutput: true,
      gain: true,
    },
  },
  labels: {
    specifiedOutput: "Spec. Output",
    saturationOutput: "Sat. Output",
    gain: "Gain",
  },
  units: {
    specifiedOutput: "dBm",
    saturationOutput: "dBm",
    gain: "dB",
  },
  colors: {
    specifiedOutput: "green",
    saturationOutput: "#FF8A8A",
    gain: "orange",
  },
};

export const BurnInLines = {
  xAxis: {
    dataKey: "timestamp",
    label: "Time Elapsed",
    unit: "hh:mm:ss",
  },
  yAxis: {
    type: "number",
    label: "Output Power",
    unit: "dBm",
    domain: [0, null],
  },
  dataKeys: [
    "inputPower",
    "specifiedOutput",
    "outputPower",
    "gain",
    "frequency",
  ],
  toolTip: {
    label: "Time Elapsed",
    unit: "",
    color: "white",
    enabled: {
      inputPower: true,
      specifiedOutput: true,
      outputPower: true,
      gain: true,
      frequency: true,
    },
  },
  labels: {
    inputPower: "Input Power",
    specifiedOutput: "Spec. Output",
    outputPower: "Output Power",
    gain: "Gain",
    frequency: "Frequency",
  },
  units: {
    inputPower: "dBm",
    specifiedOutput: "dBm",
    outputPower: "dBm",
    gain: "dB",
    frequency: "GHz",
  },
  colors: {
    frequency: "grey",
    inputPower: "lightblue",
    specifiedOutput: "green",
    outputPower: "#FF8A8A",
    gain: "orange",
  },
};

export const StatusLines = (unit) => {
  return {
    xAxis: {
      dataKey: "timestamp",
      label: "Time Elapsed",
      unit: "s",
    },
    yAxis: {
      type: "number",
      label: "Act",
      unit: unit || "",
    },
    dataKeys: ["timestamp", "act"],
    toolTip: {
      label: "Time Elapsed",
      unit: "s",
      color: "white",
      enabled: {
        str_low: true,
        str_high: true,
        str_act: true,
      },
    },
    labels: {
      str_low: "Low",
      str_high: "High",
      str_act: "Act",
    },
    units: {
      str_low: unit || "",
      str_high: unit || "",
      str_act: unit || "",
    },
    colors: {
      str_low: "grey",
      str_high: "lightblue",
      str_act: "#FF8A8A",
    },
  };
};
