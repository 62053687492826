/**
 * Constants for instrument forms and tables.
 */
export const standTextFields = [
  { id: "serial", label: "Serial" },
  { id: "name", label: "Name" },
  { id: "ipaddress", label: "IP Address" },
  { id: "port", label: "Port" },
];

export const powerMeterTextFields = [
  { id: "serial", label: "Serial" },
  { id: "name", label: "Name" },
];

export const powerHeadTextFields = [
  { id: "serial", label: "Serial" },
  { id: "name", label: "Name" },
  { id: "lowFrequency", label: "Low Freq.|GHz" },
  { id: "highFrequency", label: "High Freq.|GHz" },
  { id: "lowPower", label: "Lower Power Bound|dBm" },
  { id: "highPower", label: "Upper Power Bound|dBm" },
];

export const powerGeneratorTextFields = [
  { id: "serial", label: "Serial" },
  { id: "name", label: "Name" },
  { id: "lowFrequency", label: "Low Freq.|GHz" },
  { id: "highFrequency", label: "High Freq.|GHz" },
  { id: "lowPower", label: "Lower Power Bound|dBm" },
  { id: "highPower", label: "Upper Power Bound|dBm" },
];

export const ipTextFields = [
  { id: "ipaddress", label: "IP Address" },
  { id: "port", label: "Port" },
];

export const standHeadCells = [
  {
    id: "stand",
    label: "Stand",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  {
    id: "instruments",
    label: "Attached Instruments",
    disableSorting: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const generHeadCells = [
  {
    id: "serial",
    label: "Serial",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  {
    id: "name",
    label: "Name",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "power_range", label: "Power Range", displayOnMobile: true },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];
export const meterHeadCells = [
  {
    id: "serial",
    label: "Serial",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  {
    id: "name",
    label: "Name",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];
export const phHeadCells = [
  {
    id: "serial",
    label: "Serial",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  { id: "frequency_range", label: "Frequency Range", displayOnMobile: true },
  { id: "power_range", label: "Power Range", displayOnMobile: true },
  {
    id: "pulsing",
    label: "Pulsing",
    showOnSwipe: true,
    displayOnMobile: true,
  },
  {
    id: "actions",
    label: "Actions",
    disableSorting: true,
    showOnSwipe: true,
    hideUntilSwipe: true,
    displayOnMobile: true,
  },
];

export const StandForm = {
  initialValues: {
    serial: "",
    name: "",
    ipaddress: null,
    port: null,
  },
  formLayout: [
    {
      type: "textfields",
      fields: standTextFields,
      fieldsName: "Stand Text Fields",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const StandEditForm = {
  initialValues: {
    stand: "",
    gener: "",
    meter: "",
  },
  formLayout: [
    {
      type: "select",
      menuItems: [],
      menuItemName: "stand",
      defaultItem: null,
      disabled: true,
      menuItemTextValue: "stand",
      label: "Stand",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "gener",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Signal Generator",
    },
    {
      type: "select",
      menuItems: [],
      menuItemName: "meter",
      defaultItem: null,
      menuItemTextValue: "serial",
      label: "Power Meter",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const PowerMeterForm = {
  initialValues: {
    serial: "",
    name: "",
    scpi: false,
    ipaddress: null,
    port: null,
    separated: false,
  },
  formLayout: [
    {
      type: "textfields",
      fields: powerMeterTextFields,
      fieldsName: "Stand Text Fields",
    },
    {
      type: "toggle",
      label: "Use SCPI commands",
      name: "scpi",
    },
    {
      type: "additional",
      title: "IP Parameters",
      fields: [
        {
          type: "textfields",
          fields: ipTextFields,
          fieldsName: "ip",
        },
        {
          type: "toggle",
          label: "Separated Instrument",
          name: "separated",
        },
      ],
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const PowerGeneratorForm = {
  initialValues: {
    serial: "",
    name: "",
    lowPower: "",
    highPower: "",
    lowFrequency: "",
    highFrequency: "",
    scpi: false,
    ipaddress: null,
    port: null,
    separated: false,
  },
  formLayout: [
    {
      type: "textfields",
      fields: powerGeneratorTextFields,
      fieldsName: "Stand Text Fields",
    },
    {
      type: "toggle",
      label: "Use SCPI commands",
      name: "scpi",
    },
    {
      type: "additional",
      title: "IP Parameters",
      fields: [
        {
          type: "textfields",
          fields: ipTextFields,
          fieldsName: "ip",
        },
        {
          type: "toggle",
          label: "Separated Instrument",
          name: "separated",
        },
      ],
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};

export const PowerHeadForm = {
  initialValues: {
    serial: "",
    name: "",
    lowPower: "",
    highPower: "",
    lowFrequency: "",
    highFrequency: "",
    pulsing: false,
  },
  formLayout: [
    {
      type: "textfields",
      fields: powerHeadTextFields,
      fieldsName: "Power Head Text Fields",
    },
    {
      type: "toggle",
      label: "Pulsing",
      name: "pulsing",
    },
    {
      type: "buttons",
    },
  ],
  storedValues: null,
};
