/**
 * Styling of table component.
 */
import MuiTableHead from "@mui/material/TableHead";
import MuiTable from "@mui/material/Table";
import MuiTableCell from "@mui/material/TableCell";
import MuiTablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import MuiTableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, TableContainer } from "@mui/material";

export const Table = styled(MuiTable)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: "100%",
  "& tbody td": {
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: "300",
  },
  tableLayout: "fixed",
}));

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
  fontWeight: "600",
  textAlign: "center",
  ":hover": {
    color: "white",
    cursor: "pointer",
  },
}));

export const TableRow = styled(MuiTableRow)(({ theme }) => ({}));

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  textAlign: "center",
}));

export const TablePagination = styled(MuiTablePagination)(({ theme }) => ({
  textAlign: "center",
}));

export default function useTable(
  records,
  headCells,
  filterFn,
  count,
  handleNextPage,
  nextPage
) {
  const pages = [10, 20];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table size="small">{props.children}</Table>
      </TableContainer>
      <TblPagination />
    </Paper>
  );
  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                size="small"
              >
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => {
                      handleSortRequest(headCell.id);
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    if ((newPage + 1) * rowsPerPage > records.length && nextPage)
      handleNextPage();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={count}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
  return {
    TblContainer,
    TblHead,
    page,
    setPage,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
